/* eslint-disable react/prefer-stateless-function */
import React, {Component}  from "react";
import {
  ReUsableCard1,
  ReUsableCard2,
  ReUsableCard3,
  ReUsableCard4,
  ReUsableCard5,
  ReUsableCard6,
  ReUsableCard7,
  ReUsableCard8,
  ReUsableCard9,
  ReUsableCard10,
  ReUsableCard11,
  ReUsableCard12,
  ReUsableCard13,
  ReUsableCard14,
  ReUsableCard15,
  ReUsableCard16,
  ReUsableCard17,
  ReUsableCard20,
  ReUsableCard21,
} from "@inertiasoft/re-usable-cards"
import ShopCard from '../../commons/Cards/shopCard'
import Layout from '../../commons/Layout/layout'

class Shop extends Component{
    render(){
        return(
          <Layout>
            <ShopCard />
          </Layout>
        )
    }
}



export default Shop;


