/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Query } from "react-apollo";
import { ReUsableCard8 } from "@inertiasoft/re-usable-cards";
import { _paginatedProducts } from "./productcard.vendure";

import "./card.css";
import FullPageLoader from "../FullPageLoader/fullPageLoader";

const ShopCard = () => {
  const limit = 100000;
  const [page = 0, setPage] = useState();
  const [totalProducts = 0, setTotalProducts] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      <div className="shop-parallax-container">
        <div className="parallax" />
        <h3>All Products</h3>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="flex-container">
          <div className="shop-card-container">
            <span className="results-sec">
              Showing All Products
            </span>
            <div
              className={
                width < 900 && width > 769
                  ? "row home-img-wrapper row-cols-4"
                  : width < 769 && width > 375
                  ? "row home-img-wrapper row-cols-2"
                  : width < 376
                  ? "row home-img-wrapper row-cols-1"
                  : "row home-img-wrapper row-cols-5"
              }
            >
              <Query
                query={_paginatedProducts}
                variables={{ options: { skip: page, take: limit } }}
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <>
                        <FullPageLoader />
                      </>
                    );
                  if (error) {
                    return (
                      <a href="/shop/all">Sorry, Issue in listing Products</a>
                    );
                  }
                  if (data !== undefined) {
                    sessionStorage.setItem("save_order", false);
                    const { products } = data;
                    setTotalProducts(products.totalItems);
                    if (page < totalProducts) {
                      return products.items.map((item) => {
                        if (item.variants.length > 0) {
                          return item.variants.map((element) => {
                            return (
                              <>
                                <div className="col">
                                  <ReUsableCard8
                                    data={{
                                      selectOption: "View Details",
                                      productName: element.name,
                                      productPrice: (
                                        <NumberFormat
                                          value={element.price / 100}
                                          displayType="text"
                                          thousandSeparator
                                          prefix={
                                            element.currencyCode === "USD"
                                              ? "$"
                                              : "PKR"
                                          }
                                        />
                                      ),
                                      productLink: `/product/${element.productId}/${element.id}`,
                                      productImages: [
                                        {
                                          image1:
                                            element.featuredAsset != null
                                              ? `${element.featuredAsset.preview}?preset=large`
                                              : "",
                                          width: "260",
                                          height: "260",
                                        },
                                        {
                                          image2:
                                            element.featuredAsset != null
                                              ? `${element.featuredAsset.preview}?preset=large`
                                              : "",
                                          width: "260",
                                          height: "260",
                                        },
                                      ],
                                      backgroundColor: "#fff",
                                      fontFamily: "Open Sans, sans-serif",
                                      headingColor: "black",
                                      headingSize: "23px",
                                      textColor: "black",
                                      textSize: "12",
                                    }}
                                  />
                                </div>
                              </>
                            );
                          });
                        }
                      });
                    }
                    return (
                      <a href="/shop/all">Product not exist. View Shop again</a>
                    );
                  }
                  return (
                    <a href="/shop/all">Product not exist. View Shop again</a>
                  );
                }}
              </Query>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <button type="button" onClick={() => setPage(page + limit)}>
                <h3>Load More</h3>
                <i className="fas fa-arrow-right" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopCard;
