import { gql } from 'apollo-boost';

const _searchProductViaKeyword = gql
  `query SearchProducts ($input: SearchInput!) {   
  search (input: $input) {
    items {
      productId
      productName
      productAsset {
        id
        preview
        focalPoint {
          x
          y
        } 
      }
      productVariantId 
      productVariantName 
      productVariantAsset {
        id
        preview
        focalPoint {
          x
          y
        }
      }
      currencyCode
      description
      facetValueIds
      score
    }
    totalItems
  }   
}`;

export default _searchProductViaKeyword;

