/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-array-constructor */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo-hooks";
import { ToastContainer,toast } from "react-toastify";
import { ReUsableCard8 } from "@inertiasoft/re-usable-cards";
import NumberFormat from "react-number-format";
import ZoomImage from "../../commons/ImageZoom/imagezoom";
import ProductDetail from "../../commons/ProductDetail/productdetail";
import Layout from "../../commons/Layout/layout";
import { _singleProduct } from "./productdetails.vendure";
import "./productdetails.css";
import { data } from "jquery";

const ORDER_FRAGMENT = gql`
  fragment ActiveOrder on Order {
    id
    code
    state
    total
    currencyCode
    lines {
      id
      productVariant {
        id
        name
        currencyCode
      }
      unitPriceWithTax
      quantity
      totalPrice
      featuredAsset {
        id
        preview
      }
    }
  }
`;

const ADD_TO_CART = gql`
  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {
    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

const GET_ACTIVE_ORDER = gql`
  {
    activeOrder {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

const s = new Array();
const ProductDetails = ({ productData }) => {
  const notify = (message) => toast.error(message);
  const [allItems, setAllItems] = useState([]);
  const { error, data1 } = useQuery(GET_ACTIVE_ORDER);
  const { id, variantId } = useParams();
  const [loading, setLoading] = useState(false);

  const [addItemToOrder] = useMutation(ADD_TO_CART, {
    update: (cache, mutationResult) => {
      const { activeOrder } = cache.readQuery({
        query: GET_ACTIVE_ORDER,
      });

      cache.writeQuery({
        query: GET_ACTIVE_ORDER,
        data: {
          activeOrder: mutationResult.data.addItemToOrder,
        },
      });
    },
  });

  const addProduts = async (id, qty) => {
    console.log(`### adding this product to cart ${id} ##`);
    if (qty > 0) {
      setLoading(true);
      await addItemToOrder({
        variables: {
          productVariantId: Number(id),
          quantity: qty,
        },
      })
        .then((datra) => {
          if (datra) {
            s.push(datra);
            setAllItems(s);
            sessionStorage.setItem("save_order", true);
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(`error in adding items ${err}`);
        });
    } else {
      return notify("Failed, Quantity should be greater than Zero");
    }
  };

  const productDetails = useQuery(_singleProduct, {
    variables: {
      id,
    },
  });

  let imageSrc = null;
  let variant = null;
  let productDescription = null;
  const otherVaiants = [];

  console.log("## product id ##", id);
  console.log("## variant id ##", variantId);
  if (productDetails.data !== undefined) {
    productDetails.data.product.variants.map((element) => {
      if (variantId === element.id) {
        console.log("matched");
        variant = element;
        productDescription = productDetails.data.product.description;
        imageSrc =
          element.featuredAsset === null || element.featuredAsset === undefined
            ? productDetails.data.product.featuredAsset.preview
            : element.featuredAsset.preview;
      } else {
        console.log("not matched");
        const otherVariantsImgSrc =
          element.featuredAsset === null || element.featuredAsset === undefined
            ? productDetails.data.product.featuredAsset.preview
            : element.featuredAsset.preview;
        const price = (
          <NumberFormat
            value={element.price / 100}
            displayType="text"
            thousandSeparator
            prefix={element.currencyCode === "USD" ? "$" : "PKR"}
          />
        );

        otherVaiants.push({
          selectOption: "Select Option",
          productName: element.name,
          productPrice: price,
          productLink: `/product/${id}/${element.id}`,
          productImages: [
            {
              image1: otherVariantsImgSrc,
              width: "260",
              height: "260",
            },
            {
              image2: otherVariantsImgSrc,
              width: "260",
              height: "260",
            },
          ],
          backgroundColor: "#fff",
          fontFamily: "Open Sans, sans-serif",
          headingColor: "black",
          headingSize: "23px",
          textColor: "black",
          textSize: "12",
        });
      }
    });
  }

  if (allItems.length > 0) {
    const sum = allItems[0].data.addItemToOrder.lines.reduce(
      (accumalator, { quantity }) => accumalator + quantity,
      0
    );
    localStorage.setItem("_totalqty", sum);
  }

  console.log("locall", sessionStorage.getItem("save_order"));

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Layout>
        <div style={{ backgroundColor: "#f9f9f9" }}>
          <div className="main-details-product-container">
            {sessionStorage.getItem("save_order") === "true" ? (
              <div class="alert-message" role="alert">
                <a href="/cart" class="alert-message-btn">
                  View cart
                </a>{" "}
                <p className="alert-text">
                  <i class="fas fa-check-circle"></i> Item has been added to
                  your cart.
                </p>
              </div>
            ) : (
                ""
              )}
            <div className="product-details-container">
              <div className="product-details-wrapper row">
                <ZoomImage img={imageSrc} />
                <ProductDetail
                  // cartItems={localStorage.getItem("_totalqty")}
                  loading={loading}
                  details={{ variant }}
                  productDescription={{productDescription}}
                  addProduct={(productVariantId, qty) =>
                    addProduts(productVariantId, qty)
                  }
                />
              </div>
            </div>
            {/* <div><h2><li>Cart: {allItems.length}</li></h2></div> */}
            <div className="related-heading-container">
              <h1 className="related-heading">Related Products</h1>
            </div>
            <div className="row shop-wrapper related-wrapper">
              {otherVaiants.length > 0 ? (
                otherVaiants.map((ite) => (
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <ReUsableCard8 data={ite} />
                  </div>
                ))
              ) : (
                  <div>No Related Product</div>
                )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProductDetails;
