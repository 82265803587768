/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import {
  ReUsableContactUsForm1,
  ReUsableContactUsForm2,
  ReUsableContactUsForm3,
  ReUsableContactUsForm4,
  ReUsableContactUsForm5,
  ReUsableContactUsForm7,
  ReUsableContactUsForm8,
  ReUsableContactUsForm9,
} from "@inertiasoft/re-usable-contactus-forms";
import Carousel from "../../commons/Carousel/carousel";
import HomeCard from "../../commons/Cards/homeCard";
import Parallax from "../../commons/Parallax/parallax";
import ProductCard2 from "../../commons/Cards/productcard";
import AbsoluteCont from "../../commons/AbsoluteCont/absoluteCont";
import Slider from "../../commons/Slider/slider";
import Layout from "../../commons/Layout/layout";
import HoverCard from "../../commons/Cards/hoverCard";
import { ReUsableCard17 } from "@inertiasoft/re-usable-cards";
import gallery1 from "../../assets/images/EcommercePics/Home/New-Gallery-01-(285x315).jpg";
import gallery2 from "../../assets/images/EcommercePics/Home/New-Gallery-02-(285x315).jpg";
import gallery3 from "../../assets/images/EcommercePics/Home/New-Gallery-03-(285x315).jpg";

const contactUsForm1 = {
  color: "white",
  backgroundColor: "#301934",
  formHeading: "Okay",
  btnContent: "Forward",
  btnColor: "#301934",
  btnBackground: "white",
};

const contactUsForm2 = {
  headingColor: "white",
  headingBgColor: "#301934",
  btnContent: "Send",
  addressFontColor: "#black",
  iconBgColor: "#301934",
  btnColor: "white",
  btnBgColor: "#301934",
};

const contactUsForm3 = {
  content: [
    {
      title: "OUR RESTURANT",
      icon: "fa fa-map-marker",
      description:
        "Plot # 21-22, Allama Qazi Chowk, Block-A, Sindhi Muslim Co-operative Housing Society, Karachi.",
    },
    {
      title: "GENERAL ENQUIRIES",
      icon: "fa fa-paperclip",
      description: "info@foodsinn.co",
    },
    {
      title: "CALL US",
      icon: "fa fa-mobile-phone",
      description: "11:00 AM TO 02:00 AM",
    },
    {
      title: "OPERATION HOURS",
      icon: "fa fa-clock-o",
      description: "11:00 AM TO 02:00 AM",
    },
  ],
};

const contactUsForm4 = {
  imgSrc: "assets/images/img-01.png",
  heading: "Contact Us",
  btnContent: "Forward Email",
};

const contactUsForm5 = {
  heading: "Contact Us",
  btnContent: "Forward Email",
};

const contactUsForm7 = {
  contactDetails: {
    contactNo: "+92 303 205 4558",
    email: "info@inertiasoft.net",
  },
  socialIcons: [
    {
      imageSrc: "/assets/images/contact-form-7_facebook.jpg",
      href: "www.facebook.com",
    },
  ],
  textColor: "#ffff",
  buttonText: "Submit",
  buttonBackgroundColor: "#a3150",
  backgroundImageSrc: "../assets/images/contact-form-7.jpg",
};

const contactUsForm8 = {
  backgroundColor: "transparent",
  btnBackground: "rgb(104, 120, 214)",
  btnContent: "Submit",
  map_src:
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Block%2013A%20Gulshan-e-Iqbal,%20Karachi,%20Pakistan+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed",
  map_title: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan​",
  map_aria_label: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan",
};

const attrValues17 = {
  image: gallery1,
  imageWidth: "285",
  imageHeight: "316",
  hoveredImage: gallery1,
  hoveredImageWidth: "150",
  hoveredImageHeight: "150",
  productName: "Mackbook Pro 13",
  productPrice: "$499",
};

const attrValues18 = {
  image: gallery2,
  imageWidth: "285",
  imageHeight: "316",
  hoveredImage: gallery2,
  hoveredImageWidth: "150",
  hoveredImageHeight: "150",
  productName: "Mackbook Pro 13",
  productPrice: "$499",
};

const attrValues19 = {
  image: gallery3,
  imageWidth: "285",
  imageHeight: "316",
  hoveredImage: gallery3,
  hoveredImageWidth: "150",
  hoveredImageHeight: "150",
  productName: "Mackbook Pro 13",
  productPrice: "$499",
};

class Home extends Component {
  render() {
    return (
      <Layout>
        <Carousel />
        <HomeCard />
        <Parallax />
        <ProductCard2 />
        <AbsoluteCont />

        <div className="overlay-container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="heading-overlay-container">
                <h3>New Gallery Lookbook 2018</h3>
                <p>
                  Neque porro quisquam est qui dolorem ipsum quia dolor sit
                  amet, consectetur, adipisci velit.There is no one who loves
                  pain itself, who seeks after it and wants to have it, simply
                  because it is pain…
                </p>
                <button>Shop Now</button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
              {" "}
              <ReUsableCard17 data={attrValues17} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
              {" "}
              <ReUsableCard17 data={attrValues18} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
              {" "}
              <ReUsableCard17 data={attrValues19} />
            </div>
          </div>
        </div>
        {/* <HoverCard /> */}
        {/*<ReUsableContactUsForm8 data={contactUsForm8} */}
        <Slider />
      </Layout>
    );
  }
}

export default Home;
