/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import NumberFormat from "react-number-format";
import ProductItems from "./productItems";

import { useMutation } from "react-apollo-hooks";

import { REMOVE_ALL_ORDER_LINES } from "../../store/shipping.vendure";

const CheckoutCard = (props) => {
  const { details, currencyCode, total, totalIncludingTax, taxRate, taxAmount } = props;
  const shippingCost = 0;
  const totalWithShippingAndTaxCurrency = (
    <NumberFormat
      value={(totalIncludingTax + shippingCost) / 100}
      displayType="text"
      thousandSeparator
      prefix={currencyCode === "USD" ? "$" : "PKR"}
    />
  );
  const totalWithOutShippingCurrency = (
    <NumberFormat
      value={total / 100}
      displayType="text"
      thousandSeparator
      prefix={currencyCode === "USD" ? "$" : "PKR"}
    />
  );
  const totalWithShippingCurrency = (
    <NumberFormat
      value={(total + shippingCost) / 100}
      displayType="text"
      thousandSeparator
      prefix={currencyCode === "USD" ? "$" : "PKR"}
    />
  );
  const [removeAllOrderLines] = useMutation(REMOVE_ALL_ORDER_LINES);

  const removeAllOrder = (orderLineId) => {
    console.log(`This product is not required ${orderLineId}`);
    removeAllOrderLines().then((removeAllOrderLinesResult) => {
      if (removeAllOrderLinesResult) {
        console.log(
          `Success - Remove All Order Lines Result ${JSON.stringify(
            removeAllOrderLinesResult
          )}`
        );
      } else {
        console.log(
          `Failure - Remove All Order Lines Result ${JSON.stringify(
            removeAllOrderLinesResult
          )}`
        );
      }
    });
  };

  return (
    <>
      <div className="cart-container row">
        <div className="col-lg-8 cart-margin-bottom">
          <ProductItems details={details} />
        </div>
        <div className="total-product-price col-lg-4">
          <div className="total-product-price-wrapper">
            <h2>CART TOTALS</h2>

            <div className="sub-total-container">
              <div className="sub-total">
                <h6>Sub Total</h6>
                <h6>Shipping</h6>
                <span className="line" />
                <h6>Tax Rate</h6>
                <h6>Tax Amount</h6>
                <span className="line" />
                <h6>Total</h6>
              </div>

              <div className="sub-total">
                <h6>{totalWithOutShippingCurrency}</h6>
                <h6>${shippingCost}</h6>
                <span className="line-2" />
                <h6>${taxRate}</h6>
                <h6>${taxAmount / 100}</h6>
                <span className="line-2" />
                <h6>{totalWithShippingAndTaxCurrency}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutCard;
