/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React from "react";
import { Query } from "react-apollo";
import CreditCardIcon from "../../assets/images/credit-card.png";
import "./parallax.css";
import { _searchViaPaginatedProducts } from "../Cards/productcard.vendure";

const Parallax = () => {
  return (
    <>
      <div className="parallax-container">
        <div className="parallax-wrapper">
          <Query
            query={_searchViaPaginatedProducts}
            variables={{
              input: {
                skip: 0,
                take: 1,
                groupByProduct: true,
                facetValueIds: [44],
              },
            }}
          >
            {({ loading, data }) => {
              if (loading) return "Loading...";
              if (data !== undefined) {
                if(data.search.items.length > 0) {
                  const singleProduct = data.search.items[0];
                  singleProduct.description = singleProduct.description.replace(/<[^>]+>/g, '');
                  return (
                    <div className="row">
                      <div className="column-container col-lg-4 col-md-6 col-sm-12">
                        <h3>
                          Buy {singleProduct.productName} <br />
                        </h3>
                        <p>
                          {singleProduct.description}
                          <br />
                        </p>
                        <div className="color-logo-container">
                          <img
                            width="151"
                            height="31"
                            src={singleProduct.productAsset.preview}
                            sizes="(max-width: 151px) 100vw, 151px"
                          />
                        </div>
                        <div className="btn-conatiner">
                          <button type="button">
                            <a
                              href={`/product/${singleProduct.productId}/${singleProduct.productVariantId}`}
                            >
                              <img
                                src={CreditCardIcon}
                                alt="imgae render error"
                              />
                              Purchase
                            </a>{" "}
                          </button>
                        </div>
                      </div>
                      <div className="hidden col-lg-4 col-md-6 col-sm-12">
                        <p />
                      </div>
                      <div
                        className="vc_parallax-inner skrollable skrollable-between"
                        data-bottom-top="top: -50%;"
                        data-top-bottom="top: 0%;"
                        //   style={{
                        //   backgroundImage: `url("../../assets/images/tech-bg-parallax-home-2.jpg")`
                        // }}
                      />
                    </div>
                  );
                } return (<div>Currently there is no Exclusive Product</div>)            
              }
            }}
          </Query>
        </div>
      </div>
    </>
  );
};

export default Parallax;
