/* eslint-disable react/prefer-stateless-function */
import React, {Component}  from "react";
import ShopCardViaCategory from '../../commons/Cards/shopCardViaCategory'
import Layout from '../../commons/Layout/layout'

class categoryWise extends Component{
    render(){
        return(
          <Layout>
            <ShopCardViaCategory />
          </Layout>
        )
    }
  }

  export default categoryWise;
