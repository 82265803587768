import axios from "axios";

import config from '../../Constants/config';

const baseURL = config.backendServerUrl;

const contactEmailApiIntegration = (fullName, email, query, mobileNo) => {
	const data = {
		fullName,
		email,
		query,
		mobileNo
	};

	return axios.post(`${baseURL}/contact-us`, data).then((response) => {
		return response.data;
	}).catch(err => err);
};


export default {
	contactEmailApiIntegration
};
