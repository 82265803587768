import { gql } from 'apollo-boost';

const _categoryList = gql
    `query collections ($options: CollectionListOptions) {   
    collections (options: $options) {
        items {
            id
            name
            slug
            description
            parent {
                id
                name
            }
            children {
                id
                name
                featuredAsset {
                    preview
                }
            }
            translations {
                id
                name
                slug
                description
            }
            featuredAsset {
                preview
            }
        }
    totalItems
  }   
}`;

const _productViaCategory = gql
    `query collection ($id: ID) {   
        collection (id: $id) {
            id
            name
            slug
            description
            productVariants {
                items {
                    id
                    name
                    price
                    priceWithTax
                    currencyCode
                    productId
                    featuredAsset {
                      preview
                    }
                    assets {
                        preview
                    }
                    product {
                        featuredAsset {
                            id
                            preview
                        }
                      }
                    productId
                }
                totalItems
            }
       }
    }`;

export { _categoryList, _productViaCategory };

