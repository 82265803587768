import React from "react";
import ReUsableSlider5 from "@inertiasoft/re-usable-slider-5";
import {carouselData} from "../../Constants/Constant"

const Carousel = () => {
    return(
      <>
        <div className="carousel-container">
          <div className="carousel-wrapper">
            <ReUsableSlider5 data={carouselData} />
          </div>
        </div>
      </>
    )
}

export default Carousel
