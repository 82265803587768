import {
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_FAIL,
    SNACKBAR, SNACKBAR_CLOSE, FAIL_SNACKBAR, SNACKBAR_CLEAR,
} from "../actions/types";

const initialState = { isSubscribe: false, subscribe: '', snackBarOpen: false, snackBarMessage: '' };

export default function subscribeReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isSubscribe: true,
                subscribe: payload
            };
        case SUBSCRIBE_FAIL:
            return {
                ...state,
                isSubscribe: false,
            };
        case SNACKBAR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarMessage: 'Thank you, you have successfully subscribed to this website'
            }
        case FAIL_SNACKBAR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarMessage: 'Sorry, something went wrong. Please try again'
            }
        case SNACKBAR_CLOSE:
            return {
                ...state,
                snackBarOpen: false
            }
        case SNACKBAR_CLEAR:
            return {
                ...state,
                snackBarOpen: false
            }
        default:
            return state;
    }
}
