/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-apollo-hooks";
import { Query } from "react-apollo";

import Layout from "../../commons/Layout/layout";
import "./checkout.css";
import CheckoutCard from "./checkoutCard";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ADD_ADDRESS_TO_ORDER,
  GET_ACTIVE_ORDER_FOR_CHECKOUT,
  TRANSITION_TO_ARRANGING_PAYMENT,
  GET_ACTIVE_ORDER,
  ADD_PAYMENT_TO_ORDER,
} from "../../store/checkout.vendure";
import FullPageLoader from "../../commons/FullPageLoader/fullPageLoader";

let activeOrderCheckout = null;

const AddressDetails = () => {
  const notify = (message) => toast.error(message);
  const history = useHistory();
  const setcustomerShippingAddress = {};

  activeOrderCheckout = useQuery(GET_ACTIVE_ORDER_FOR_CHECKOUT, {
    fetchPolicy: "network-only",
  });
  const [addAddressToOrder] = useMutation(ADD_ADDRESS_TO_ORDER);
  const [addPaymentToOrder] = useMutation(ADD_PAYMENT_TO_ORDER);
  const [isLoading, setLoading] = useState(false);
  const [transitionToArrangingPayment] = useMutation(
    TRANSITION_TO_ARRANGING_PAYMENT
  );

  // const history = useHistory();
  const handlePlaceOrder = () => {
    addPaymentToOrder({
      variables: {
        input: {
          method: "example-payment-provider",
          metadata: {
            foo: "bar",
          },
        },
      },
    })
      .then((response) => {
        setLoading(false);
        console.log("addPaymentToOrder success");
        localStorage.setItem("orderNo", response.data.addPaymentToOrder.code);
        localStorage.setItem(
          "totalPrice",
          response.data.addPaymentToOrder.total / 100
        );
        // show snack bar of order is placed here
        localStorage.setItem("_totalqty", 0);
        history.push("/confirmation");
      })
      .catch((error) => {
        setLoading(false);
        notify("Internal server error in Payment. Please try again");
        // show snakbar here
        console.log(`Internal Server error in setting Payment. Please try again ${error}`);
      });
  };

  const handleCustomerShippingAddessSubmission = (formData) => {
    const {
      fullName,
      company,
      streetLine1,
      streetLine2,
      city,
      province,
      countryCode,
      postalCode,
      phoneNumber,
    } = formData;

    if (Object.keys(formData).length === 0 && formData.constructor === Object) {
      return notify("Please fill the required (*) fields. ");
    }

    if (streetLine1 === null || countryCode === "null") {
      return notify("Please fill the required (*) fields. ");
    }

    setLoading(true);

    if (activeOrderCheckout.loading) {
      console.log("loading2");
      return <div>Loading...</div>;
    }
    if (activeOrderCheckout.error || !activeOrderCheckout.data.activeOrder) {
      console.log("error2");
      return (
        <div>
          Error!{" "}
          {activeOrderCheckout.error
            ? activeOrderCheckout.error.message
            : "No active order"}
        </div>
      );
    }

    if (activeOrderCheckout.data.activeOrder.lines.length > 0) {
      addAddressToOrder({
        variables: {
          input: {
            fullName,
            company,
            streetLine1,
            streetLine2,
            city,
            province,
            countryCode,
            postalCode,
            phoneNumber,
          },
        },
      })
        .then(() => {
          console.log("called transitionToArrangingPayment");
          transitionToArrangingPayment().then(() => {
            console.log("addAddressToOrder success");
            handlePlaceOrder(); // as currently only payment on delivery option is available
          });
        })
        .then(() => {
          console.log(`parallel process`);
        })
        .catch((error) => {
          setLoading(false);
          notify("Internal Server error in setting Address. Please try again");
          // show snakbar here
          console.log(`Internal Server Error in Setting Address ${error}`);
        });
    } else {
      notify("Please add atleast one item in the Order to proceed.");
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Layout>
        <div className="shop-parallax-container">
          <div className="parallax" />
          <h3>Checkout</h3>
        </div>{" "}
        <br />
        <Query query={GET_ACTIVE_ORDER}>
          {({ loading, data }) => {
            if (loading) return <FullPageLoader />;
            if (data !== undefined || data != null) {
              const { activeOrder } = data;
              if (activeOrder != null) {
                console.log("data is defined");
                return (
                  // <CheckoutCard
                  //   details={activeOrder}
                  //   currencyCode={activeOrder.currencyCode}
                  //   total={activeOrder.total}
                  // />
                  <CheckoutCard
                    details={activeOrder}
                    currencyCode={activeOrder.currencyCode}
                    total={activeOrder.totalBeforeTax}
                    totalIncludingTax={activeOrder.total}
                    taxRate={`${activeOrder.taxSummary[0].taxRate}%`}
                    taxAmount={`${activeOrder.taxSummary[0].taxTotal}`}
                  />
                );
              }
              return "Loading...";
            }
          }}
        </Query>
        <div className="checkout-form-1">
          <div className="billing-form-wrapper row">
            <div className="billing-form col-lg-12">
              <h1>Customer Shipping Address</h1>
              <p>
                For now billing address will be the same as of shipping address
              </p>
              <div className="main-flex-container">
                <div className="first-name-container">
                  <label htmlFor="first-name">FULL NAME</label>
                  <input
                    className="first_name"
                    type="text"
                    onChange={(e) => {
                      setcustomerShippingAddress.fullName = e.target.value;
                    }}
                  />
                </div>
              </div>

              <div className="company-container">
                <label htmlFor="company-name">COMPANY NAME</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.company = e.target.value;
                  }}
                />
              </div>

              <div className="last-name-container">
                <label htmlFor="last-name">STREET 1 *</label>
                <input
                  className="last_name"
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.streetLine1 = e.target.value;
                  }}
                />
              </div>

              <div className="street-container">
                <label htmlFor="street-name">STREET 2</label>
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setcustomerShippingAddress.streetLine2 = e.target.value;
                  }}
                />
              </div>

              <div className="postcode-container">
                <label htmlFor="postcode-name">POSTCODE / ZIP</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.postalCode = e.target.value;
                  }}
                />
              </div>

              <div className="country-container">
                <label htmlFor="country-name">COUNTRY CODE</label>
                <select onChange={(e) => {
                  setcustomerShippingAddress.countryCode = e.target.value;
                }}>
                  <option value="null">Select Country Code</option>
                  <option value="PK">PK</option>
                  <option value="US">US</option>
                </select>
                {/* <input
                  type="text"
                  placeholder="Use PK"

                /> */}
              </div>

              <div className="state-container">
                <label htmlFor="state-name">STATE</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.state = e.target.value;
                  }}
                />
              </div>

              <div className="state-container">
                <label htmlFor="state-name">CITY</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.city = e.target.value;
                  }}
                />
              </div>

              <div className="phone-container">
                <label htmlFor="phone-name">PHONE</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setcustomerShippingAddress.phoneNumber = e.target.value;
                  }}
                />
              </div>
              <button
                type="button"
                className="proceed-checkout-btn"
                onClick={() =>
                  handleCustomerShippingAddessSubmission(
                    setcustomerShippingAddress
                  )
                }
              >
                {isLoading ? (
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={35}
                    width={45}
                    visible={true}
                  //3 secs
                  />
                ) : (
                    "Place Order"
                  )}
              </button>
              <p>For now Payment will be on Delivery</p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddressDetails;
