/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { ReUsableContactUsForm2 } from "@inertiasoft/re-usable-contactus-forms";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../../commons/Layout/layout";
import "./contactus.css";

import {
  contactEmailApiIntegration,
  clearSnackbar,
} from "../../store/actions/contact";
import store from "../../store/store";

// const attrValues2 = {
//   headingBgColor: "#301934",
//   addressFontColor: "#black",
//   iconBgColor: "#301934",
//   btnColor: "white",
//   btnBgColor: "#301934",
//   headingIconColor: "white",
//   headingContent: "Contact Us",
//   headingFontSize: "",
//   headingFontFamily: "Montserrat",
//   headingFontWeight: "",
//   headingColor: "#fff",
//   tagLine: "We'll write rarely, but only the best content.",
//   tagLineFontSize: "30px",
//   mapHeadingFontSize: "",
//   mapHeadingFontFamily: "Montserrat",
//   mapHeadingFontWeight: "bold",
//   mapHeadingFontColor: "black",
//   btnContent: "Send",
//   nameLabelText: "Name",
//   emailLabelText: "Email",
//   subjectLabelText: "Contact No.",
//   messageLabelText: "Enter Message",
//   addressText: "New York, 94126",
//   countryText: "United States",
//   number: "+001-1234567",
//   timings: "09:00 - 18:00",
//   email: "contact@lorem.com",
//   email2: "contact-2@lorem.com",
//   map_src:
//     "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Block%2013A%20Gulshan-e-Iqbal,%20Karachi,%20Pakistan+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed",
//   map_title: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan​",
//   map_aria_label: "Block-13/A, Gulshan-e-Iqbal, Karachi, Pakistan",
//   labelFontSize: "20px",
//   buttonFontSize: "20px",
//   buttonFontWeight: "bolder",
//   buttonFontFamily: "Montserrat",
//   buttonWidth: "100px",
//   buttonHeight: "50px",
// };

const attrValues2 = {

  headingIconColor: "white",
  headingContent: "Contact Us",
  headingFontSize: "",
  headingFontFamily: "Montserrat",
  headingFontWeight: "",
  headingColor: "white",
  headingBgColor: "#301934",
  iconBgColor: "rgb(104, 120, 214)",
  addressFontColor: "#black",
  tagLine: "",
  tagLineFontSize: "30px",
  mapHeadingFontSize: "",
  mapHeadingFontFamily: "Montserrat",
  mapHeadingFontWeight: "bold",
  mapHeadingFontColor: "black",
  nameLabelText: "Name*",
  emailLabelText: "Email*",
  subjectLabelText: "Contact No.",
  messageLabelText: "Enter Message*",
  addressText: "New York, 94126",
  countryText: "United States",
  number: "+001-1234567",
  timings: "09:00 - 18:00",
  email: "contact@lorem.com",
  email2: "contact-2@lorem.com",
  map_src:
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=New+York,+NY,+USA/@40.6976701,-74.2598737,10z&t=&z=14&ie=UTF8&iwloc=B&output=embed",
  map_title: "New York, United States​",
  map_aria_label: "New York, United States",
  labelFontSize: "15px",
  buttonFontSize: "13px",
  buttonFontWeight: "bolder",
  buttonFontFamily: "Montserrat",
  buttonWidth: "100px",
  buttonHeight: "45px",
  btnColor: "white",
  btnBgColor: "#301934",
  btnContent: "Send"
};

class ContactUs extends Component {
  state = {
    formSubmitted: false,
  };

  handleSubmit = ({ fullName, email, query, mobileNo }) => {
    store
      .dispatch(contactEmailApiIntegration(fullName, email, query, mobileNo))
      .then(() => {
        window.location.reload();
      });
  };

  render() {
    return (
      <>
        <Layout>
          <div className="shop-parallax-container2">
            <div className="parallax2" />
            <h3>Contact Us</h3>
          </div>

          <div className="ecom-contact-us-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="inner-container">
                    <h4>Address</h4>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply random text.
                      Please email us at info@lorel.com
                    </p>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="inner-container">
                    <h4>Opening Hours</h4>
                    <p>Monday to Friday: 9am-9pm</p>
                    <p>Saturday to Sunday: 9am-11pm</p>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="inner-container">
                    <h4>Careers</h4>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply random text.
                      Please email us at info@lorel.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReUsableContactUsForm2
            data={attrValues2}
            handleSubmit={this.handleSubmit}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.props.snackBarOpen}
            autoHideDuration={5000}
            onClose={this.props.clearSnackbar}
            message={<span id="message-id">{this.props.snackBarMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.props.onSnackBarClick}
              >
                {" "}
                x{" "}
              </IconButton>,
            ]}
          />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    snackbar: state.contact.snackBarOpen,
    snackBarMessage: state.contact.snackBarMessage,
    snackBarOpen: state.contact.snackBarOpen,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onSnackBarClick: bindActionCreators(clearSnackbar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
