import axios from "axios";

import config from '../../Constants/config';

const baseURL = config.backendServerUrl;

const subscribeApiIntegration = (email) => {
	const data = {
		email,
	};

	return axios.post(`${baseURL}/website/subscribe`, data).then((response) => {
		return response.data;
	}).catch(err => err);
};

export default {
	subscribeApiIntegration,
};
