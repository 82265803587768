import React, { useState } from "react";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Query } from "react-apollo";
import { ReUsableCard8 } from "@inertiasoft/re-usable-cards";
import { _productViaCategory } from "../Layout/productCategory.vendure";

import "./card.css";
import FullPageLoader from "../FullPageLoader/fullPageLoader";

const ShopCard = () => {
  const { categoryId } = useParams();
  const [allProducts = "All Products", setAllProducts] = useState();

  return (
    <>
      <div className="shop-parallax-container">
        <div className="parallax" />
        <h3>{allProducts}</h3>
      </div>

      <div className="flex-container">
        <div className="shop-card-container">
          <div className="row shop-wrapper">
            <Query query={_productViaCategory} variables={{ id: categoryId }}>
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <>
                      <FullPageLoader />
                    </>
                  );
                if (error) {
                  return (
                    <a href="/shop/all">
                      Product not exist. View other Products
                    </a>
                  );
                }
                if (data !== undefined) {
                  sessionStorage.setItem("save_order", false);
                  const { collection } = data;
                  if(collection.productVariants.items.length > 0 ) {
                    setAllProducts(`All ${collection.name} Products`);
                    return collection.productVariants.items.map((element) => (
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <ReUsableCard8
                          data={{
                            selectOption: "View Details",
                            productName: element.name,
                            productPrice: (
                              <NumberFormat
                                value={element.price / 100}
                                displayType="text"
                                thousandSeparator
                                prefix={
                                  element.currencyCode === "USD" ? "$" : "PKR"
                                }
                              />
                            ),
                            productLink: `/product/${element.productId}/${element.id}`,
                            productImages: [
                              {
                                image1: `${
                                  element.featuredAsset != null
                                    ? element.featuredAsset.preview
                                    : element.product.featuredAsset.preview
                                }?preset=large`,
                                width: "260",
                                height: "260",
                              },
                              {
                                image2: `${
                                  element.featuredAsset != null
                                    ? element.featuredAsset.preview
                                    : element.product.featuredAsset.preview
                                }?preset=large`,
                                width: "260",
                                height: "260",
                              },
                            ],
                            backgroundColor: "#fff",
                            fontFamily: "Open Sans, sans-serif",
                            headingColor: "black",
                            headingSize: "23px",
                            textColor: "black",
                            textSize: "12",
                          }}
                        />
                      </div>
                    ));
                  }
                  return (
                    <a href="/shop/all">Product not exist. View other Products</a>
                  );
                }
                return (
                  <a href="/shop/all">Product not exist. View other Products</a>
                );
              }}
            </Query>
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ShopCard;
