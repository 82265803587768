/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/prop-types */
import React from "react";

const SearchedProduct = (props) => {
  const { details } = props;
  return (
    <>
      <div className="search-product-item">
        <br />
        {details.items.map((item) => (
          <div>
            <a href={`/product/${item.productId}/${item.productVariantId}`}>
              <img
                src={`${
                  item.productVariantAsset === undefined ||
                  item.productVariantAsset === null
                    ? item.productAsset.preview
                    : item.productVariantAsset.preview
                }?preset=tiny`}
                alt="alt-image"
              />
              <p>{item.productVariantName}</p>
            </a>
          </div>
        ))}
        <div>
          <span />
        </div>
      </div>
      <br />
    </>
  );
};

export default SearchedProduct;
