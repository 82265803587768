import { gql } from "apollo-boost";

export const ORDER_FRAGMENT = gql`
  fragment ActiveOrder on Order {
    id
    code
    state
    total
    totalBeforeTax
    currencyCode
    taxSummary {
      taxRate
      taxBase
      taxTotal
    }
    lines {
      id
      productVariant {
        id
        name
        currencyCode
        price
        priceWithTax
        assets {
          id
          source
        }
      }
      linePrice
      lineTax
      linePriceWithTax
      unitPrice
      unitPriceWithTax
      quantity
      totalPrice
      featuredAsset {
        id
        preview
      }
    }
  }
`;

export const GET_ACTIVE_ORDER = gql`
  {
    activeOrder {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADJUST_ITEM_QUANTITY = gql`
  mutation AdjustItemQuantity ($orderLineId: ID!, $quantity: Int) {
    adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ORDER_LINE = gql`
  mutation RemoveOrderLine ($orderLineId: ID!) {
    removeOrderLine(orderLineId: $orderLineId) {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ALL_ORDER_LINES = gql`
  mutation RemoveAllOrderLines {
    removeAllOrderLines {
      ...ActiveOrder
    }
  }
  ${ORDER_FRAGMENT}
`;

