/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { ReUsableCard8 } from "@inertiasoft/re-usable-cards";
import ZoomImage from "../../commons/ImageZoom/imagezoom";
import { shopData } from "../../Constants/Constant";
import aboutusImg from "../../assets/images/Buy-a-Mac-960x360.jpg";
import Layout from "../../commons/Layout/layout";
import ProductCard2 from "../../commons/Cards/productcard";
import AbsoluteCont from "../../commons/AbsoluteCont/absoluteCont";
import "./about.css";
import logoImg from "../../assets/images/Customer-Logo 01-(132x54)BW-3.svg";

class AboutUs extends Component {
  render() {
    return (
      <>
        <Layout>
          <div className="container max-container-width">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 main-about-card">
                <div className="ecom-about-us-container">
                  <h2>About Us</h2>
                  <div className="zig-zag-line">
                    <div className="zig-zag-inner" />
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 main-about-card">
                <div className="ecom-img-container">
                  <img
                    width="800"
                    height="430"
                    src={aboutusImg}
                    alt="render error"
                  />
                  <h4>What we really do ?</h4>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit…
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 main-about-card">
                <div className="ecom-img-container">
                  <img
                    width="800"
                    height="430"
                    src={aboutusImg}
                    alt="render error"
                  />
                  <h4>Our Vision</h4>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit…
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 main-about-card">
                <div className="ecom-img-container">
                  <img
                    width="800"
                    height="430"
                    src={aboutusImg}
                    alt="render error"
                  />
                  <h4>History of Beginning</h4>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit…
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ecom-investor-container">
            <div className="container ecom-padding-container">
              <div className="ecom-investor-heading">
                <h2>Investors</h2>
                <div className="zig-zag-line-2">
                  <div className="zig-zag-inner" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-3 col-6 investor-padding">
                  <img src={logoImg} alt="render error" />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-3 col-6 investor-padding">
                  <img src={logoImg} alt="render error" />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-3 col-6 investor-padding">
                  <img src={logoImg} alt="render error" />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-3 col-6 investor-padding">
                  <img src={logoImg} alt="render error" />
                </div>
              </div>
            </div>
          </div>

          <ProductCard2 />
          <AbsoluteCont />
        </Layout>
      </>
    );
  }
}

export default AboutUs;
