/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useMutation } from "react-apollo-hooks";
import { REMOVE_ALL_ORDER_LINES } from "../../store/shipping.vendure";
import {
  ADJUST_ITEM_QUANTITY,
  REMOVE_ORDER_LINE,
} from "../../store/shipping.vendure";
import { event } from "jquery";
import Loader from "react-loader-spinner";

const ProductItems = (props) => {
  const { details } = props;
  const [removeOrderLine] = useMutation(REMOVE_ORDER_LINE);
  const [adjustItemQty] = useMutation(ADJUST_ITEM_QUANTITY);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(5);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const removeProduct = (orderLineId) => {
    console.log(`This product is not required ${orderLineId}`);
    removeOrderLine({
      variables: {
        orderLineId: Number(orderLineId),
      },
    }).then((removeOrderLineResult) => {
      if (removeOrderLineResult) {
        console.log(
          `Success - Remove Order Line Result ${JSON.stringify(
            removeOrderLineResult
          )}`
        );
      } else {
        console.log(
          `Failure - Remove Order Line Result ${JSON.stringify(
            removeOrderLineResult
          )}`
        );
      }
    });
  };

  const updateProductQty = (qty, orderLineId) => {
    setLoading(true);
    setQuantity(qty.target.value);
    const cartValue = parseInt(qty.target.value);
    console.log(
      `The quantity of this product needs to be update ${orderLineId} ${qty.target.value}`
    );
    adjustItemQty({
      variables: {
        orderLineId: Number(orderLineId),
        quantity: cartValue,
      },
    }).then((updatQtyResult) => {
      if (updatQtyResult) {
        setLoading(false);
        console.log(
          `Success - Remove Order Line Result ${JSON.stringify(updatQtyResult)}`
        );
      } else {
        setLoading(false);
        console.log(
          `Failure - Remove Order Line Result ${JSON.stringify(updatQtyResult)}`
        );
      }
    });
  };
  console.log("detailsss", details);

  const [removeAllOrderLines] = useMutation(REMOVE_ALL_ORDER_LINES);

  const removeAllOrder = (orderLineId) => {
    setDeleteLoading(true);
    console.log(`This product is not required ${orderLineId}`);
    removeAllOrderLines().then((removeAllOrderLinesResult) => {
      if (removeAllOrderLinesResult) {
        localStorage.setItem("_totalqty", 0);
        setDeleteLoading(false);
        window.location.reload();
        console.log(
          `Success - Remove All Order Lines Result ${JSON.stringify(
            removeAllOrderLinesResult
          )}`
        );
      } else {
        setDeleteLoading(false);
        console.log(
          `Failure - Remove All Order Lines Result ${JSON.stringify(
            removeAllOrderLinesResult
          )}`
        );
      }
    });
  };

  const IncrementItem = () => {
    setQuantity(quantity + 1);
  };
  const DecreaseItem = () => {
    setQuantity(quantity - 1);
  };

  console.log("datails line", details.lines);

  return (
    <>
      <div className="main-product-item-container">
        <div className="loader-container"></div>
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Product</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {details.lines.length === 0 ? (
              <tr className="cart-empty">
                <td></td>
                <td></td>
                <td>
                  <p>Cart is Empty</p>
                </td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              details.lines.map((item) => {
                return (
                  <tr>
                    <td data-label="Remove">
                      <button
                        className="cross-btn"
                        type="button"
                        onClick={() => removeProduct(item.id)}
                      >
                        X
                      </button>
                    </td>
                    <td data-label="Product Image">
                      <img
                        src={`${
                          item.productVariant.assets.length > 0
                            ? item.productVariant.assets[0].source
                            : item.featuredAsset.preview
                        }?preset=tiny`}
                        alt="product"
                      />
                    </td>
                    <td data-label="Product" className="product-item-name">
                      {item.productVariant.name}
                    </td>
                    <td data-label="Total" className="product-amount">
                      <NumberFormat
                        value={item.unitPrice / 100}
                        displayType="text"
                        thousandSeparator
                        prefix={details.currencyCode === "USD" ? "$" : "PKR"}
                      />
                    </td>
                    <td data-label="Quantity" className="quantity-border">
                      {" "}
                      <input
                        defaultValue={item.quantity}
                        onChange={(event) => updateProductQty(event, item.id)}
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="remove-all-btn">
          <button
            className="remove-items"
            type="button"
            onClick={() => removeAllOrder()}
          >
            {isDeleteLoading ? (
              <Loader
                type="ThreeDots"
                color="#222"
                height={25}
                width={45}
                visible={true}
                //3 secs
              />
            ) : (
              "Remove All Order Items"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductItems;
