/* eslint-disable radix */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./productdetail.css";
import NumberFormat from "react-number-format";
import FullPageLoader from "../FullPageLoader/fullPageLoader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const ProductDetail = (props) => {
  console.log("loadinggg", props.loading);
  console.log("### props.details ###");
  console.log(props.productDescription);
  const [productQty, setProductQty] = useState(1);
  let price = 0;
  if (props.details.variant !== undefined && props.details.variant !== null) {
    price = (
      <NumberFormat
        value={
          props.details.variant.price !== undefined ||
            props.details.variant.price != null
            ? props.details.variant.price / 100
            : 0
        }
        displayType="text"
        thousandSeparator
        prefix={props.details.variant.currencyCode === "USD" ? "$" : "PKR"}
      />
    );
    return (
      <>
        {/* <div>Hello World</div> */}

        <div className="product-detail-container col-lg-6 col-md-6 col-sm-12">
          <h1 className="product-name">
            {props.details.variant.name ||
              "65″ 2018 Q7C 4K Curved Smart QLED TV"}
          </h1>
          <p className="product-price">{price || "No-Price"}</p>
          <p className="product-detail">
            {((props.productDescription !== undefined ||
              props.productDescription !== null) ? "Q9F gives you 100% colour volume, which means it maximizes the range of accurate, rich and detailed colours you can see on TV. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur."
              : "Q9F gives you 100% colour volume, which means it maximizes the range of accurate, rich and detailed colours you can see on TV. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit…"
            )}
          </p>

          <div className="product-buttons-container">
            <div className="product-btn">
              <div className="qty-btn" />
              <input
                type="number"
                className="input-text qty text"
                name="quantity"
                title="Qty"
                min="1"
                value={productQty}
                onChange={(e) => {
                  setProductQty(parseInt(e.target.value));
                }}
              />
              <div className="qty-btn" />
            </div>

            <button
              // disabled={props.loading ? true : false}
              name="add-to-cart"
              value=""
              className="add_to_cart_button button alt"
              onClick={() =>
                props.addProduct(props.details.variant.id, productQty)
              }
            >
              {props.loading ? (
                <Loader
                  type="ThreeDots"
                  color="#222"
                  height={35}
                  width={45}
                  visible={true}
                //3 secs
                />
              ) : (
                  "Add to cart"
                )}
            </button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <FullPageLoader />
    </>
  );
};

export default ProductDetail;
