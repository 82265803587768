/* eslint-disable react/style-prop-object */
import React from "react";
import "./absoluteCont.css";

const AbsoluteCont = () => {
  return (
    <>
      <div className="main-absolute-container">
        <div className="main-absolute-wrapper">
          <h3>Buy a Mac or Ipad Pro. We'll supply the Beats.</h3>
          <button type="button">Continue Reading</button>
        </div>
      </div>
    </>
  );
};

export default AbsoluteCont;
