/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import product from '../../assets/images/product.jpg'

const ImageZoom = (props) => {
    return(
      <>
        <div className="image-zoom-style col-lg-6 col-md-6 col-sm-12">
          <Zoom>
            <img
              alt="that wanaka tree"
              src={props.img || "https://i0.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/product-image-20.jpg?fit=680%2C680&ssl=1"}
              width="724"
            />
          </Zoom>
        </div>
      </>
    )
}

export default ImageZoom;
