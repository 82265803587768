const logoImg = "../assets/images/logo.png";

// For Footer 1

const footer1 = {
  footerBackgroundColor: "#000",
  footerLinksFontColor: "#ffae00",
  footerLinksFontSize: "14px",
  footerLinksFontFamily: "Montserrat",
  footerLinksFontWeight: "bold",
  border: "solid thin #dc3545",
  footerImg: {
    src:
      "https://websitedemos.net/agency-02/wp-content/uploads/sites/30/2020/06/site-logo-white.svg",
    width: "150px",
    alt: "Footer-Image",
  },
  footerDescription: {
    fontColor: "#dc3545",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  footerLinks: {
    Company: [
      {
        name: "Home",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "About",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "Service",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "Contacts",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
    ],
    Business: [
      {
        name: "Project",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "Our Team",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "Facts",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      {
        name: "Customers",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
    ],
  },
  contact: {
    title: "Get In Touch",
    details: [
      {
        address: "Rt. 66, Downtown, Washington, DC",
        icon: "map-marker",
        href: "#",
        fontColor: "#28a745",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "bold",
      },
      {
        email: "info@example.com",
        icon: "envelope",
        href: "#",
        fontColor: "#28a745",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "bold",
      },
      {
        phoneNo: "1-800-1234-567",
        icon: "phone",
        href: "#",
        fontColor: "#28a745",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "bold",
      },
      {
        mobileNo: "+001 987-654-3210",
        icon: "mobile",
        href: "#",
        fontColor: "#28a745",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: "bold",
      },
    ],
  },
  footerSocials: {
    icons: [
      {
        name: "facebook",
        href: "#",
        fontColor: "#28a745",
      },
      {
        name: "twitter",
        href: "#",
        fontColor: "#28a745",
      },
      {
        name: "linkedin",
        href: "#",
        fontColor: "#28a745",
      },
      {
        name: "pinterest",
        href: "#",
        fontColor: "#28a745",
      },
    ],
  },
  copyRights: {
    content: "Copyright © 2020 Digital Agency | Powered by Digital Agency",
    fontColor: "#28a745",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
};

// For Footer 2
const footer2 = {
  footer: {
    title: "Get in Touch",
    backgroundColor: "#fff",
    fontColor: "#444444",
    fontSize: "16px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  subscribeForm: {
    successMsg: {
      fontColor: "#263b5e",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    errMsg: {
      fontColor: "#263b5e",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    emailField: {
      placeHolder: "Email Address",
    },
    content: {
      details: "Subscribe us for updates regarding new Products and Offers",
      fontColor: "rgb(142, 142, 142)",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    button: {
      backgroundColor: "#5e2ced",
      fontColor: "#FFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
      content: "Subscribe",
    },
  },
  footerLinks: {
    Shop: [
      {
        name: "Home",
        href: "/",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "All Products",
        href: "/shop/all",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      }
    ],
    Help: [
      {
        name: "Contact Us",
        href: "/contact",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "About Us",
        href: "/about",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  socials: {
    title: "Social Network",
    icons: [
      {
        name: "facebook",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "twitter",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "linkedin",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "pinterest",
        href: "#",
        backgroundColor: "#5e2ced",
      },
    ],
  },
};

const attrValues3 = {
  title: "Get in Touch",
  backgroundColor: "",
  fontColor: "#fff",
  fontSize: "10px",
  fontFamily: "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
  fontWeight: "normal",
  logo: {
    src:
      "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png",
    imgAlt: "InertiaSoft",
    dataImg:
      "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png",
  },
  content: {
    description:
      "We are a young company always looking for new and creative ideas to help you with our products in your everyday work.",
    href: "http://google.com",
    label: "Our Team",
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
  },
  navigation: {
    headingOne: {
      content: "Contact",
      addressDetails: "Via Rossini 10, 10136 Turin Italy",
      contactDetails: "Phone: (0039) 333 12 68 347",
      email: {
        label: "Email:",
        address: " hello@domain.com",
        href: "http://inertiasoft.net",
      },
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    headingTwo: {
      content: "Links",
      addressDetails: "Via Rossini 10, 10136 Turin Italy",
      contactDetails: "Phone: (0039) 333 12 68 347",
      email: {
        label: "Email:",
        address: " hello@domain.com",
      },
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  },
  copyRight: {
    href: "http://inertisoft.net",
    companyName: "InertiaSoft",
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
  },
  linkOneItems: [
    {
      href: "http://ineritsoft.net",
      content: "Top",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://google.com",
      content: "Section-1",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-2",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-3",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
  linkTwoItems: [
    {
      href: "http://ineritsoft.net",
      content: "Section-4",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Affiliates",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Terms",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
  socialMediaLinks: [
    {
      iconClass: "fa fa-facebook",
      href: "http://facebook.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-twitter",
      href: "http://twitter.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-google-plus",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-pinterest",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
};

const footer3 = {
  title: "Get in Touch",
  backgroundColor: "",
  fontColor: "#fff",
  fontSize: "10px",
  fontFamily: "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
  fontWeight: "normal",
  logo: {
    src:
      "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png",
    imgAlt: "InertiaSoft",
    dataImg:
      "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png",
  },
  content: {
    description:
      "We are a young company always looking for new and creative ideas to help you with our products in your everyday work.",
    href: "http://google.com",
    label: "Our Team",
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
  },
  navigation: {
    headingOne: {
      content: "Contact",
      addressDetails: "Via Rossini 10, 10136 Turin Italy",
      contactDetails: "Phone: (0039) 333 12 68 347",
      email: {
        label: "Email:",
        address: " hello@domain.com",
        href: "http://inertiasoft.net",
      },
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    headingTwo: {
      content: "Links",
      addressDetails: "Via Rossini 10, 10136 Turin Italy",
      contactDetails: "Phone: (0039) 333 12 68 347",
      email: {
        label: "Email:",
        address: " hello@domain.com",
      },
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  },
  copyRight: {
    href: "http://inertisoft.net",
    companyName: "InertiaSoft",
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
  },
  linkOneItems: [
    {
      href: "http://ineritsoft.net",
      content: "Top",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://google.com",
      content: "Section-1",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-2",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-3",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
  linkTwoItems: [
    {
      href: "http://ineritsoft.net",
      content: "Section-4",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Affiliates",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Terms",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
  socialMediaLinks: [
    {
      iconClass: "fa fa-facebook",
      href: "http://facebook.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-twitter",
      href: "http://twitter.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-google-plus",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-pinterest",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "Montserrat",
      fontWeight: "normal",
    },
  ],
};

// // For Footer 3
// const footer3 = {
//   title: "Get in Touch",
//   backgroundColor: "",
//   fontColor: "#fff",
//   fontSize: "10px",
//   fontFamily: "Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
//   fontWeight: "normal",
//   logo: {
//     src: "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png",
//     imgAlt: "InertiaSoft",
//     dataImg: "https://www.clipartmax.com/png/middle/20-202056_instagram-instagram-business-card-icon.png"
//   },
//   content: {
//     description: "We are a young company always looking for new and creative ideas to help you with our products in your everyday work.",
//     href: "http://google.com",
//     label: "Our Team",
//     fontColor: "#fff",
//     fontSize: "14px",
//     fontFamily: "Montserrat",
//     fontWeight: "normal"
//   },
//   navigation: {
//     headingOne: {
//       content: "Contact",
//       addressDetails: "Via Rossini 10, 10136 Turin Italy",
//       contactDetails: "Phone: (0039) 333 12 68 347",
//       email: {
//         label: "Email:",
//         address: " hello@domain.com",
//         href: "http://inertiasoft.net"
//       },
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     headingTwo: {
//       content: "Links",
//       addressDetails: "Via Rossini 10, 10136 Turin Italy",
//       contactDetails: "Phone: (0039) 333 12 68 347",
//       email: {
//         label: "Email:",
//         address: " hello@domain.com",
//       },
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//   },
//   copyRight: {
//     href: "http://inertisoft.net",
//     companyName: "InertiaSoft",
//     fontColor: "#fff",
//     fontSize: "14px",
//     fontFamily: "Montserrat",
//     fontWeight: "normal"
//   },
//   linkOneItems: [
//     {
//       href: "http://ineritsoft.net",
//       content: "Top",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"

//     },
//     {
//       href: "http://google.com",
//       content: "Section-1",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       href: "http://ineritsoft.net",
//       content: "Section-2",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       href: "http://ineritsoft.net",
//       content: "Section-3",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     }
//   ],
//   linkTwoItems: [
//     {
//       href: "http://ineritsoft.net",
//       content: "Section-4",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       href: "http://ineritsoft.net",
//       content: "Affiliates",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       href: "http://ineritsoft.net",
//       content: "Terms",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     }
//   ],
//   socialMediaLinks: [
//     {
//       iconClass: "fa fa-facebook",
//       href: "http://facebook.com",
//       label: "",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       iconClass: "fa fa-twitter",
//       href: "http://twitter.com",
//       label: "",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       iconClass: "fa fa-google-plus",
//       href: "http://google.com",
//       label: "",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     },
//     {
//       iconClass: "fa fa-pinterest",
//       href: "http://google.com",
//       label: "",
//       fontColor: "#fff",
//       fontSize: "14px",
//       fontFamily: "Montserrat",
//       fontWeight: "normal"
//     }
//   ]
// };

// For Footer 4
const footer4 = [
  {
    backgroundColor: "#f5f5f5",
    heading: {
      content: "Stay in touch",
      fontColor: "#000",
      fontSize: "27px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    salesEmail: {
      content: "sales@example.com",
      fontColor: "#333",
      fontSize: "17px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    supportEmail: {
      content: "support@example.com",
      fontColor: "red",
      fontSize: "17px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    phone: {
      content: "(123) 456-789",
      fontColor: "#333",
      fontSize: "17px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    footerSocial: {
      icons: [
        {
          name: "facebook",
          href: "#",
          fontColor: "#fff",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: "normal",
        },
        {
          name: "twitter",
          href: "#",
        },
        {
          name: "linkedin",
          href: "#",
        },
        {
          name: "youtube",
          href: "#",
        },
        {
          name: "rss",
          href: "#",
        },
      ],
    },
  },
  {
    heading: {
      content: "Latest Events",
      fontColor: "#000",
      fontSize: "27px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    events: [
      {
        img: "http://placehold.it/60x60",
        content: "vulputate velit esse consequat.",
        date: "September 30, 2016",
        href: "#",
        fontColor: "#969696;",
        fontSize: "10px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        img: "http://placehold.it/60x60",
        content: "vulputate velit esse consequat.",
        date: "September 30, 2016",
        href: "#",
        fontColor: "#969696;",
        fontSize: "10px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Opening Hours",
      fontColor: "#000",
      fontSize: "27px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    openingTimes: [
      {
        icon: "times",
        day: "monday",
        time: "closed",
        fontColor: "red",
        fontSize: "13px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        icon: "check",
        day: "tue-fri",
        time: "8am - 12am",
        fontColor: "red",
        fontSize: "13px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        icon: "check",
        day: "sat-sun",
        time: "7am - 1am",
        fontColor: "red",
        fontSize: "13px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        icon: "check",
        day: "holydays ",
        time: "12pm - 12am",
        fontColor: "red",
        fontSize: "13px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Latest Events Images",
      fontColor: "#000",
      fontSize: "27px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
    },
    events: [
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#000",
        fontSize: "27px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#000",
        fontSize: "27px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#333",
        fontSize: "20px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#333",
        fontSize: "20px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#333",
        fontSize: "20px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        image: "http://placehold.it/85x85",
        href: "#",
        fontColor: "#333",
        fontSize: "20px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
];

// For Footer 5
const footer5 = [
  {
    logoImg,
    href: "#",
    fontColor: "#fff",
    fontSize: "50px",
    fontFamily: "sans-serif",
    fontWeight: "normal",
    backgroundColor: "#3c3d41",
  },
  {
    heading: {
      content: "Get started",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "Home",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Signup",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Downloads",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "About Us",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "Company Information",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Contact us",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Reviews",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Support",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Help Desk",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Forums",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    socialIcons: [
      {
        name: "twitter",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "facebook",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "google-plus",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    contactUsButton: {
      content: "Contact US",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "normal",
      backgroundColor: " #d84b6b",
      borderRadius: "20px",
      border: "none",
      width: "150px",
    },
    copyRightSection: {
      content: "© Copyright Text 2020",
      backgroundColor: "#333333",
      fontColor: "#ccc",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "normal",
      width: "100%",
    },
  },
];

// For Footer 7

const footer7 = [
  {
    backgroundColor: "#2196f3",
    footerHeading: {
      content: "Footer Content",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "",
    },
    footerContent: {
      content:
        "Here you can use rows and columns to organize your footer content.",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "",
    },
  },
  {
    heading: {
      content: "Link1",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "",
    },
    links: [
      {
        name: "Link1",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link2",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link3",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link4",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Link2",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "sans-serif",
      fontWeight: "",
    },
    links: [
      {
        name: "Link1",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link2",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link3",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Link4",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    copyRightSection: {
      backgroundColor: "#1a78c2",
      content: {
        description: "© Copy Rights at InertiaSoft.net 2020",
        fontColor: "#f7f7f7",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "",
      },
      anchor: {
        content: " InertiaSoft",
        href: "http://inertiasoft.net",
        fontColor: "#f7f7f7",
        fontSize: "",
        fontFamily: "sans-serif",
        fontWeight: "",
      },
    },
  },
];

// For Footer 8

const footer8 = {
  backgroundColor: "#262626",
  width: "100%",
  logo: {
    href: "http://google.com",
    src:
      "http://foodsinn.co/wp-content/uploads/2020/01/Logo-for-website-03.png",
    altText: "Logo-Image",
    fontColor: "#fff",
    fontSize: "31px",
    fontFamily: "Montserrat, sans-serif;",
    fontWeight: "normal",
  },
  socialIcons: [
    {
      image: "http://foodsinn.co/wp-content/uploads/2020/01/facebook.png",
      href: "https://www.facebook.com/foodsinn/",
      altText: "Icon-Image",
      fontColor: "#fff",
      fontSize: "31px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      image: "http://foodsinn.co/wp-content/uploads/2020/01/instagram.png",
      href: "https://www.instagram.com/foods_inn/",
      altText: "Icon-Image",
      fontColor: "#fff",
      fontSize: "31px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
  ],
  copyRightSection: {
    content: {
      date: "© 2020, FoodsInn, ",
      content: " All Right Reserved Developed By ",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    anchor: {
      content: " InertiaSoft",
      href: "http://inertiasoft.net",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
  },
};

// For Footer 9

const footer9 = {
  backgroundColor: "#3f51b5",
  separatorLine: {
    backgroundColor: "#fff",
    margin: "0 15%",
  },
  links: [
    {
      name: "About Us",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Products",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Awards",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Help",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Contact",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "bold",
    },
  ],
  socialIcons: [
    {
      name: "facebook",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "twitter",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "google-plus",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "linkedin",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "instagram",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "pinterest",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
  ],
  content: {
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remaperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
    fontColor: "#fff",
    fontSize: "15px",
    fontFamily: "Montserrat, sans-serif;",
    fontWeight: "normal",
    lineHeight: "1.7rem",
  },
  copyRightSection: {
    backgroundColor: "#324191",
    content: {
      date: "© 2020, FoodsInn, ",
      content: " All Right Reserved Developed By ",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
    anchor: {
      content: " InertiaSoft",
      href: "http://inertiasoft.net",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily: "Montserrat, sans-serif;",
      fontWeight: "normal",
    },
  },
};

// For Footer 10
const footer10 = {
  content: "COPY RIGHTS, all rights are reserved for the",
  copyRightYearFrom: "2020",
  copyRightYearTo: "2025",
  backgroundColor: "#000",
  website: "inertiasoft",
  fontColor: "#fff",
  fontSize: "13px",
  fontFamily: "Montserrat",
  fontWeight: "normal",
  borderTop: "1px solid red",
};

// For Footer 11

const footer11 = {
  backgroundColor: "rgb(44, 26, 106)",
  footerAbout: {
    content: {
      description:
        "Tell us more about what you want to accomplish with your organisation. We help you think of possible solutions in an ever-changing digital world.",
      fontColor: "rgb(153, 153, 204)",
      fontSize: "28.125px",
      fontFamily: "karla, sans-serif",
      fontWeight: "400",
    },
    emailAddress: {
      description: "hello@Inertia.co",
      fontColor: "#fff",
      fontSize: "28.125px",
      fontFamily: "karla, sans-serif",
      fontWeight: "400",
    },
  },
  footerAddress: {
    heading: {
      content: "OFFICE",
    },
    backgroundColor: "background-color: rgb(44, 26, 106)",
    content: "166 Main Street, Beverly Hills,",
    mailTo: "hello@Inertia.co",
    contact: '+0085 346 2188"',
    fontColor: "#fff",
    fontSize: "1.125rem",
    fontFamily: "karla, sans-serif",
    fontWeight: "400",
  },
  footerDivider: {
    borderTopColor: "rgba(255,255,255,.05)",
    border: "0",
    borderTop: "1px solid rgba(0,0,0,.1)",
  },
  footerLinkHeading: {
    fontColor: "#99c",
    fontSize: ".875rem",
    fontFamily: "karla, sans-serif",
    fontWeight: "700",
  },
  footerLinks: {
    Help: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Term &amp; Conditions",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
    ],
    Quick: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
    ],
    Links: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "1.125rem",
        fontFamily: "karla, sans-serif",
        fontWeight: "400",
      },
    ],
  },
  copyRightSection: {
    content: {
      footerYear: "© 2019",
      footerName: "INERTIA",
      footerRights: ". All Rights Reserved.",
      fontColor: "#fff",
      fontSize: "1.125rem",
      fontFamily: "karla, sans-serif",
      fontWeight: "normal",
    },
    designBy: {
      label: "Design By",
      name: "InertiaSoft",
      fontColor: "#fff",
      fontSize: "1.125rem",
      fontFamily: "karla, sans-serif",
      fontWeight: "normal",
    },
  },
};

export {
  footer1,
  footer2,
  footer3,
  footer4,
  footer5,
  footer7,
  footer8,
  footer9,
  footer10,
  footer11,
};
