export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_FAIL = "SUBSCRIBE_FAIL";
export const VENDURE_SUCCESS = "VENDURE_SUCCESS";
export const VENDURE_FAIL = "VENDURE_FAIL";
export const SNACKBAR = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';
export const FAIL_SNACKBAR = 'FAIL_SNACKBAR';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const CONTACT_US_SNACKBAR = 'CONTACT_US_SNACKBAR_OPEN';
export const CONTACT_US_SNACKBAR_CLOSE = 'CONTACT_US_SNACKBAR_CLOSE';
export const CONTACT_US_FAIL_SNACKBAR = 'CONTACT_US_FAIL_SNACKBAR';
export const CONTACT_US_SNACKBAR_CLEAR = 'CONTACT_US_SNACKBAR_CLEAR';
