/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from "react";
import { ReUsableCard8 } from "@inertiasoft/re-usable-cards";
import { useQuery } from "react-apollo-hooks";
import NumberFormat from "react-number-format";
import { _searchViaPaginatedProducts } from "./productcard.vendure";

import "./card.css";
import FullPageLoader from "../FullPageLoader/fullPageLoader";

const HomeCard = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const products = [];
  const searchFeaturedProducts = useQuery(_searchViaPaginatedProducts, {
    variables: {
      input: { skip: 0, take: 12, groupByProduct: true, facetValueIds: [40] },
    },
  });

  if (searchFeaturedProducts.data !== undefined) {
    if (searchFeaturedProducts.data.search.items.length > 0) {
      searchFeaturedProducts.data.search.items.map((element) => {
        const minPrice = (
          <NumberFormat
            value={element.price.min / 100}
            displayType="text"
            thousandSeparator
            prefix={element.currencyCode === "USD" ? "$" : "PKR"}
          />
        );

        const maxPrice = (
          <NumberFormat
            value={element.price.max / 100}
            displayType="text"
            thousandSeparator
            prefix={element.currencyCode === "USD" ? "$" : "PKR"}
          />
        );
        products.push({
          selectOption: "View Details",
          productName: element.productVariantName,
          productPrice: `${element.currencyCode === "USD" ? "$" : "PKR"}${(element.price.min) / 100} - ${element.currencyCode === "USD" ? "$" : "PKR"}${(element.price.max) / 100}`,
          productLink: `/product/${element.productId}/${element.productVariantId}`,
          productImages: [
            {
              image1:
                element.productVariantAsset !== null
                  ? element.productVariantAsset.preview
                  : element.productAsset.preview,
              width: "350",
              height: "350",
            },
            {
              image2:
                element.productVariantAsset !== null
                  ? element.productVariantAsset.preview
                  : element.productAsset.preview,
              width: "350",
              height: "350",
            },
          ],
          backgroundColor: "#fff",
          fontFamily: "Open Sans, sans-serif",
          headingColor: "black",
          headingSize: "23px",
          textColor: "black",
          textSize: "12",
        });
      });
    }
  } else {
    return <FullPageLoader />;
  }

  return (
    <>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="flex-container">
          <div className="shop-card-container">
            <div className="home-heading-cont">
              <span>— IneritaSoft Ecommerce Store</span>
              <h3>Our Featured Products</h3>
            </div>
            <div
              className={
                width < 900 && width > 769
                  ? "row home-img-wrapper row-cols-4"
                  : width < 769
                    ? "row home-img-wrapper row-cols-2"
                    : "row home-img-wrapper row-cols-5"
              }
            >
              {products.length > 0 ? (
                products.map((item) => (
                  <div
                    onClick={() => sessionStorage.setItem("save_order", false)}
                    className="col"
                  >
                    <ReUsableCard8 data={item} />
                  </div>
                ))
              ) : (
                  <div>
                    <h3>No Featured Product exist</h3>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCard;
