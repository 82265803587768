/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from "react";
import { ReUsableNavbar9 } from "@inertiasoft/re-usable-nav-bars";
import { ReUsableSidebar2 } from "@inertiasoft/re-usable-side-bars";
import { Query } from "react-apollo";
// import { useQuery } from 'react-apollo-hooks';
// import { Link } from 'react-router-dom'
import _searchViaPaginatedProducts from "./searchedProduct.vendure";
import Footer from "../Footer/footer";
import Logo from "../../assets/images/ecommerce-web-logo.svg";
import Heart from "../../assets/images/heart-shape-outline.png";
import Basket from "../../assets/images/shopping-cart-empty-side-view.png";
import SearchIcon from "../../assets/images/magnifying-search-lenses-tool.png";
import browseCategoryIcon from "../../assets/images/right-menu-bars.png";
import SearchedProduct from "./searchedProduct";
import sideBarLogo from "../../assets/images/side_bar_logo.jpg";
import { _categoryList } from "./productCategory.vendure";
import "./layout.css";

const attrValues9 = {
  header: {
    logo: `${Logo}`,
    logoHrefUrl: "/",
    searchIcon: `${SearchIcon}`,
    browseCategoryIcon: `${browseCategoryIcon}`,
    shoppingDetails: {
      title: "",
      favouriteIcon: `${Heart}`,
      cartIcon: `${Basket}`,
      href: "/cart",
      cart: {
        itemQty: localStorage.getItem("_totalqty") || 0,
        marginRight: "5px",
        fontWeight: "bolder",
        fontSize: "larger",
        fontFamily: "emoji",
        fontColor: "blue",
      },
    },
  },
  navBarBackgroundColor: "#fff",
  leftNavItems: [
    {
      href: "/",
      name: "Home",
      fontSize: "15px",
      fontColor: "#222222",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/shop/all",
      name: "Shop",
      fontSize: "15px",
      fontColor: "#222222",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/about",
      name: "About Us",
      fontSize: "15px",
      fontColor: "#222222",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/contact",
      name: "Contact Us",
      fontSize: "15px",
      fontColor: "#222222",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
  ],
};
const Layout = (props) => {
  let _keywords;
  const [searchKeyword, setSearchKeyword] = useState();
  const [isDisplay, setDisplay] = useState(false);

  const searchProducts = (e) => {
    if (e.key === 'Enter' || e === 'clicked') {
      console.log(`Now keyword is: ${_keywords}`);
      setSearchKeyword(_keywords);
      setDisplay(!isDisplay);
    } else {
      console.log(`Not an 'Enter' key`);
    }
  };

  return (
    <>
      <div className="col-md-4">
        <div className="searchBox-container">
          <div className="form-style">
            <input
              type="search"
              placeholder="Search products…"
              className="search-input"
              onKeyDown={(e) => searchProducts(e)}
              onChange={(e) => {
                _keywords = e.target.value;
              }}
            />

            <div className="search-btn-container">
              <button
                type="button"
                className="search-btn"
                onClick={() => searchProducts('clicked')}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAHqAAAB6gBuRybzQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEoSURBVDiNjdKxSgNBGATgb1PZpAkW2lydQlCs7MTO0k4CNnmHFL6EDxBs7NVeUNAHELTQQhAUUphCsbFIoazF7cl6XmIWFu7+nZmd/98RY1RtLOMAV3jHI47RR8ixP5yMvIs3xCn7AkWjQCJXwCfsYxt7OMRXOntGu+bacnbzaR2QQBt4TZhhXeAgu/kPOQP2MpdFLnCVivvTyBl4nLA7Va2FVeW69f+qMGtVoZX6h8U5BCrMRy5wk743ZzFDCB2spN+H/Kyv7OsTGzP6P0q4ERbyIQZlSKLyqXo1YicjRwyaglQoQ1KBxjjDNSZ+J/IF3aYotzHUHOMRBon8SyRUfWTDKrCufKqPNLDzGOMkhNDFJZaSy62ZwZkyzG7m5O6Pg3lWcnKC+2/42Ty2FwVJAgAAAABJRU5ErkJggg=="
                  alt="error while rendering"
                />
              </button>
            </div>
            <div
              className={
                isDisplay
                  ? "search-products"
                  : "search-products search-products-display"
              }
            >
              {searchKeyword !== undefined ? (
                <Query
                  query={_searchViaPaginatedProducts}
                  variables={{ input: { term: searchKeyword } }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return "";
                    if (data !== undefined) {
                      const { search } = data;
                      _keywords = searchKeyword;
                      return (
                        <>
                          <div className="search-product-inner">
                            <h1>Products</h1>
                            <button
                              className="search-inner-btn"
                              onClick={() => setDisplay(false)}
                            >
                              <i className="fas fa-times" />
                            </button>
                          </div>
                          {
                            (search.totalItems === 0 ? <p>No Product exist</p> : <SearchedProduct details={search} />)
                          }
                        </>
                      );
                    }
                  }}
                </Query>
              ) : (
                  false
                )}
            </div>
            <div className="">
              <Query query={_categoryList} variables={{ id: 5 }}>
                {({ loading, error, data }) => {
                  if (loading) return "";
                  const sideBarData = {
                    bodyBackgroundColor: "#fff",
                    zIndex: "999",
                    logo: `${sideBarLogo}`,
                    icon: "fa fa-home",
                    sideBarItems: [],
                    listItemFontSize: "16px",
                    listItemFontFamily: "Helvetica, Arial, sans-serif",
                    listItemFontColor: "#222",
                    listItemFontWeight: "",
                  };
                  if (data !== undefined) {
                    console.log(
                      `Product categories JSON ${JSON.stringify(data)}`
                    );
                    const { collections } = data;
                    if (collections.items.length > 0) {
                      collections.items.map((item) => {
                        sideBarData.sideBarItems.push({
                          image: "",
                          name: item.name,
                          href:
                            item.children.length > 0 ? "#" : `/shop/${item.id}`,
                          subItems:
                            item.children.length > 0
                              ? item.children.map((e) => {
                                const child = {
                                  id: e.id,
                                  name: e.name,
                                  href: `/shop/${e.id}`,
                                  image: "",
                                };
                                return child;
                              })
                              : [],
                        });
                      });
                      return <ReUsableSidebar2 data={sideBarData} />;
                    } 
                    return (<div><h3>Product categories does not exist</h3></div>)
                  }
                  return (<div><h3>Product categories does not exist</h3></div>)
                }}
              </Query>
            </div>
          </div>
        </div>
      </div>
      <ReUsableNavbar9 data={attrValues9} />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
