import React from "react";
import Layout from "../../commons/Layout/layout";
import "./confirmationPage.css";

export default function ConfiramtionPage() {
  if (localStorage.getItem("orderNo") !== undefined) {
    localStorage.setItem("_totalqty", 0);
  }
  return (
    <div>
      <Layout>
        <div className="shop-parallax-container">
          <div className="parallax" />
          <h3>Checkout</h3>
        </div>{" "}
        <br />
        <div className="main-confirmation-container">
          <p>Thank you. Your order has been received.</p>
          <div className="confirmation-container">
            <div className="confirmation-column border-cont">
              <p>Order Number</p>
              <p>#{localStorage.getItem("orderNo")}</p>
            </div>

            <div className="confirmation-column">
              <p>Total</p>
              <p>${localStorage.getItem("totalPrice")}</p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
