/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-apollo-hooks";
import { Query } from "react-apollo";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "./checkout.css";
import Layout from "../../commons/Layout/layout";
import CheckoutCard from "./checkoutCard";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_ACTIVE_ORDER_FOR_CHECKOUT,
  SET_ORDER_CUSTOMER,
  GET_ACTIVE_ORDER,
} from "../../store/checkout.vendure";
import FullPageLoader from "../../commons/FullPageLoader/fullPageLoader";

let activeOrderCheckout = null;

const Checkout = () => {
  const notify = (message) => toast.error(message);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const setCustomerDetails = {};
  const activeOrderResult = {
    data: null,
    error: null,
    loading: null,
  };
  activeOrderCheckout = useQuery(GET_ACTIVE_ORDER_FOR_CHECKOUT, {
    fetchPolicy: "network-only",
  });
  const [setOrderCustomer] = useMutation(SET_ORDER_CUSTOMER);

  const handleCustomerBasicDetailsSubmission = (formData) => {
    const { firstName, lastName, emailAddress } = formData;
    if (Object.keys(formData).length === 0 && formData.constructor === Object) {
      return notify("Please fill the required (*) fields. ");
    }

    if (firstName === null || lastName === null || emailAddress === null) {
      return notify("Please fill the required (*) fields. ");
    }
    setLoading(true);

    if (activeOrderResult.loading) {
      return <div>Loading...</div>;
    }

    if (
      activeOrderResult.data != null &&
      activeOrderResult.data !== undefined
    ) {
      if (
        activeOrderResult.data.activeOrder &&
        activeOrderResult.data.activeOrder.state === "ArrangingPayment"
      ) {
        console.log("Order state is Arranging Payment");
        notify("Currently there is no Active Order.");
      } else {
        console.log("Order state is not at Arranging Payment");

        if (activeOrderResult.error || !activeOrderResult.data.activeOrder) {
          return (
            <div>
              Error!{" "}
              {activeOrderResult.error
                ? activeOrderResult.error.message
                : "No active order"}
            </div>
          );
        }
      }

      if (activeOrderCheckout.loading) {
        console.log("loading-set order");
        return <div>Loading...</div>;
      }
      if (activeOrderCheckout.error || !activeOrderCheckout.data.activeOrder) {
        console.log("error-set order");
        return (
          <div>
            Error!{" "}
            {activeOrderCheckout.error
              ? activeOrderCheckout.error.message
              : "No active order"}
          </div>
        );
      }

      if (activeOrderResult.data.activeOrder.lines.length > 0) {
        setOrderCustomer({
          variables: {
            input: {
              firstName,
              emailAddress,
              lastName,
            },
          },
        })
          .then(() => {
            setLoading(false);
            console.log("Customer Basic Details are successfully set");
            history.push("/addressdetails");
          })
          .catch((error) => {
            setLoading(false);
            notify("Internal server error in setting Customer basic details. Please try again.");
            // show snakbar here
            console.log(`Internal Server Error ${error}`);
          });
      } else {
        notify("Please add atleast one item in the Order to proceed.");
      }
    } else {
      notify("Currently there is no Active Order.");
      console.log("Currently there is no Active Order");
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Layout>
        <br />
        <div className="shop-parallax-container">
          <div className="parallax" />
          <h3>Checkout</h3>
        </div>
        <Query query={GET_ACTIVE_ORDER}>
          {({ loading, data, error }) => {
            if (loading) return <FullPageLoader />;
            if (data !== undefined || data != null) {
              const { activeOrder } = data;
              if (activeOrder != null) {
                activeOrderResult.data = data;
                activeOrderResult.error = error;
                activeOrderResult.loading = loading;
                console.log("data is defined");
                return (
                  <>
                    <CheckoutCard
                      details={activeOrder}
                      currencyCode={activeOrder.currencyCode}
                      total={activeOrder.totalBeforeTax}
                      totalIncludingTax={activeOrder.total}
                      taxRate={`${activeOrder.taxSummary[0].taxRate}%`}
                      taxAmount={`${activeOrder.taxSummary[0].taxTotal}`}
                    />
                    <div className="checkout-form-1">
                      <div className="billing-form-wrapper row">
                        <div className="billing-form col-lg-12">
                          <h1>Customer Basic Details</h1>
                          <div className="main-flex-container">
                            <div className="first-name-container">
                              <label htmlFor="first-name">FIRST NAME *</label>
                              <input
                                className="first_name"
                                type="text"
                                onChange={(e) => {
                                  setCustomerDetails.firstName = e.target.value;
                                }}
                              />
                            </div>

                            <div className="last-name-container">
                              <label htmlFor="last-name">LAST NAME *</label>
                              <input
                                className="last_name"
                                type="text"
                                onChange={(e) => {
                                  setCustomerDetails.lastName = e.target.value;
                                }}
                              />
                            </div>
                          </div>

                          <div className="email-container">
                            <label htmlFor="email-name">EMAIL ADDRESS *</label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setCustomerDetails.emailAddress = e.target.value;
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="proceed-checkout-btn"
                            onClick={() =>
                              handleCustomerBasicDetailsSubmission(setCustomerDetails)
                            }
                          >
                            {isLoading ? (
                              <Loader
                                type="ThreeDots"
                                color="#fff"
                                height={35}
                                width={45}
                                visible={true}
                              />
                            ) : (
                                "Save"
                              )}
                          </button>
                          <p>For now Payment will be on Delivery</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
              return "Loading...";
            }
          }}
        </Query>
      </Layout>
    </>
  );
};

export default Checkout;
