import { SUBSCRIBE_SUCCESS, SUBSCRIBE_FAIL, SNACKBAR, FAIL_SNACKBAR } from "./types";

import SubscribeService from "../services/subscribe.service";

export const subscribeApiIntegration = (email) => (dispatch) => {
	return SubscribeService.subscribeApiIntegration(email).then(
		(data) => {
			if (data.message === "Thank you, you have successfully subscribed to this website") {
				dispatch({
					type: SUBSCRIBE_SUCCESS,
					payload: { susbscribe: data }
				})
				dispatch({
					type: SNACKBAR,
				})
				Promise.resolve();
			}
			else {
				const msg = data.Error.toString();
				dispatch({
					type: SUBSCRIBE_FAIL,
					payload: { msg }
				});

				dispatch({
					type: FAIL_SNACKBAR,
				});

				Promise.reject();
			}
		}).
		catch((error) => {
			const msg =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: SUBSCRIBE_FAIL,
				payload: { msg }
			});

			dispatch({
				type: FAIL_SNACKBAR,
			});

			return Promise.reject();
		}
		)
}

export const showSuccessSnackbar = message => {
	return dispatch => {
		dispatch({ type: "SNACKBAR_SUCCESS", message });
	};
};

export const clearSnackbar = () => {
	return dispatch => {
		dispatch({ type: "SNACKBAR_CLEAR" });
	};
};
