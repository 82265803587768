/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import { ReUsableFooter1, ReUsableFooter2,
  ReUsableFooter3,ReUsableFooter4,ReUsableFooter5,ReUsableFooter7,
  ReUsableFooter8, ReUsableFooter9,ReUsableFooter10,ReUsableFooter11 } from "@inertiasoft/re-usable-footers";
import {
  footer1, footer2, footer3,
  footer4, footer5, footer7,
  footer8, footer9, footer10,
  footer11
} from "../../Constants/footer";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { Snackbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import { subscribeApiIntegration, clearSnackbar } from "../../store/actions/subscribe";
import store from "../../store/store";

class Footer extends Component {

  handleSubscribe = ({ email }) => {
    store.dispatch(subscribeApiIntegration(email)).then(() => { });
  }

  render() {
    return (
      <>
        <div className="Footer">
          <div className="Footer-wrapper">
            <ReUsableFooter2 data={footer2} handleSubscribe={this.handleSubscribe} />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={this.props.snackBarOpen}
              autoHideDuration={5000}
              onClose={this.props.clearSnackbar}
              message={<span id="message-id">{this.props.snackBarMessage}</span>}
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={this.props.onSnackBarClick}> x </IconButton>
              ]}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    snackbar: state.subscribe.snackBarOpen,
    snackBarMessage: state.subscribe.snackBarMessage,
    snackBarOpen: state.subscribe.snackBarOpen,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    onSnackBarClick: bindActionCreators(clearSnackbar, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
