import {
    CONTACT_SUCCESS,
    CONTACT_FAIL,
    CONTACT_US_SNACKBAR, CONTACT_US_SNACKBAR_CLOSE, CONTACT_US_FAIL_SNACKBAR, CONTACT_US_SNACKBAR_CLEAR,
} from "../actions/types";

const initialState = { isSaveContact: false, contact: '', snackBarOpen: false, snackBarMessage: '' };

export default function contactUsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CONTACT_SUCCESS:
            return {
                ...state,
                isSaveContact: true,
                contact: payload
            };
        case CONTACT_FAIL:
            return {
                ...state,
                isSaveContact: false,
            };
        case CONTACT_US_SNACKBAR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarMessage: 'Thank you for contacting us, Message is successfully received get back to you shortly'
            }
        case CONTACT_US_FAIL_SNACKBAR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarMessage: 'Sorry, something went wrong. Please try again'
            }
        case CONTACT_US_SNACKBAR_CLOSE:
            return {
                ...state,
                snackBarOpen: false
            }
        case CONTACT_US_SNACKBAR_CLEAR:
            return {
                ...state,
                snackBarOpen: false
            }
        default:
            return state;
    }
}
