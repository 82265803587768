import { CONTACT_SUCCESS, CONTACT_FAIL, CONTACT_US_SNACKBAR, CONTACT_US_FAIL_SNACKBAR } from "./types";

import ContactService from "../services/contactus.service";

export const contactEmailApiIntegration = (fullName, email, query, mobileNo) => (dispatch) => {
    return ContactService.contactEmailApiIntegration(fullName, email, query, mobileNo).then(
        (data) => {
            if (data.message === "ContactUs query saved successfully") {
                dispatch({
                    type: CONTACT_SUCCESS,
                    payload: { contact: data }
                })
                dispatch({
                    type: CONTACT_US_SNACKBAR,
                })
                Promise.resolve();
            }
            else {
                const msg = data.Error.toString();
                dispatch({
                    type: CONTACT_FAIL,
                    payload: { msg }
                });

                dispatch({
                    type: CONTACT_US_FAIL_SNACKBAR,
                });

                Promise.reject();
            }
        }).
        catch((error) => {
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CONTACT_FAIL,
                payload: { msg }
            });

            dispatch({
                type: CONTACT_US_FAIL_SNACKBAR,
            });

            return Promise.reject();
        }
        )
}

export const showSuccessSnackbar = message => {
    return dispatch => {
        dispatch({ type: "SNACKBAR_SUCCESS", message });
    };
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({ type: "CONTACT_US_SNACKBAR_CLEAR" });
    };
};
