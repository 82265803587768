import { combineReducers } from "redux";
import loginreducer from "./auth";
import contactUsReducer from "./contact";
import subscribeReducer from "./susbcribe";
import vendureReducer from "./vendure";

export default combineReducers({
  login: loginreducer,
  contact: contactUsReducer,
  subscribe: subscribeReducer,
  vendure: vendureReducer,
});
