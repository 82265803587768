import { gql } from 'apollo-boost';

// gql`
//     mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {
//       addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
//         ...ActiveOrder
//       }
//     }
//     ${ORDER_FRAGMENT}`

// gql`
//   query {
//     movie(id: 1) {
//       id
//       title
//     }
//   }`;

// const GET_DOG_PHOTO = gql`
//   query Dog($breed: String!, $otherCriteria: Object) {
//     dog(breed: $breed, otherCriteria: $otherCriteria) {
//       id
//       displayImage
//     }
//   }
// `;
// const paginatedProducts = gql `query GetProducts ($options: { $skip: Int, $take: Int }) {
//   products (options: $options: { skip: $skip, take: $take }) {
//       items {
//           name
//           slug
//           description
//           variants {
//             id
//           }
//           assets {
//             source
//           }
//       }
//    } 
// }`;

const paginatedProducts = gql`query { 
  products (options: { skip:0, take: 2 }) {
            items {
                name
                slug
                description
                variants {
                  id
                }
                assets {
                  source
                }
              }
        }   
}`;

// query  GetProduct ($id: ID!) { 
const _paginatedProducts = gql
  `query GetProducts ($options: ProductListOptions) {   
  products (options: $options) {
    items {
      id
      name
      variants {
        id
        name
        priceWithTax
        price
        currencyCode
        featuredAsset {
          preview
        }
        assets {
          preview
        }
        productId
      }
      featuredAsset {
        id
        preview
      }
    }
    totalItems
  }   
}`;

const _searchViaPaginatedProducts = gql
  `query SearchProducts ($input: SearchInput!) {   
  search (input: $input) {
    items {
      productId
      productName
      productAsset {
        id
        preview
        focalPoint {
          x
          y
        } 
      }
      productVariantId 
      productVariantName 
      productVariantAsset {
        id
        preview
        focalPoint {
          x
          y
        }
      }
      price {
        ... on PriceRange {
          min
          max
        }
      }
      priceWithTax {
        ... on PriceRange {
          min
          max
        }
      }
      currencyCode
      description
      facetValueIds
      score
    }
    totalItems
  }   
}`;

//   {
//     products {
//       items {
//         name
//         slug
//         description
//         variants {
//           id
//         }
//         assets {
//           source
//         }
//       }
//       skip (input: $input)
//     }
//   }

// const allProducts = gql`
//     {
//       products {
//         items {
//           name
//           slug
//           description
//           variants {
//             id
//           }
//           assets {
//             source
//           }
//         }
//       }
//     }
//   `;

const singleProduct = gql`query {
    product (id: 1) {
                  name
                  slug
                  description
                  variants {
                    id
                  }
                  assets {
                    source
                  }
                }
               } `;


const _singleProduct = gql` query GetProduct ($id: ID!) {  
                product (id: $id) {
                  name
                  slug
                  description
                  variants {
                    id
                    name
                    price
                    priceWithTax
                    currencyCode
                    assets {
                        id
                        name
                        source
                    }
                  }
                  featuredAsset {
                    id
                    preview
                  }
                } }`;

export { paginatedProducts, singleProduct, _paginatedProducts, _singleProduct, _searchViaPaginatedProducts };
