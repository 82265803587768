import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import ApolloClient, { InMemoryCache } from "apollo-boost";

import Shop from "./components/Shop/shop";
import CategoryWise from "./components/Shop/categoryWise";
import Home from "./components/Home/home";
import ContactUs from "./components/ContactUs/contactus";
import ProductDetails from "./components/ProductDetails/productdetails";
import Checkout from "./components/Checkout/checkout";
import AddressDetails from "./components/Checkout/address";

import AboutUs from "./components/AboutUs/about";
import "./App.css";
import FullPageLoader from "./commons/FullPageLoader/fullPageLoader";
import ConfiramtionPage from "./components/ConfirmationPage/confiramtionPage";

import config from './Constants/config'

// import Checkout from './components/Cart/cart' // not in use

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    // const baseURL = "http://demo-custom-ecommerce.inertiasoft.net:3000";
    // const baseURL = "http://localhost:3000";
    const baseURL = config.vendureServerUrl;

    const cache = new InMemoryCache();
    const client = new ApolloClient({
      cache,
      credentials: "include",
      uri: `${baseURL}/shop-api`,
      resolvers: {
        Mutation: {
          setActiveOrderId: (_, { id }, { cache: apolloCache }) => {
            apolloCache.writeData({
              data: {
                activeOrderId: id,
              },
            });
          },
        },
      },
    });

    cache.writeData({
      data: {
        activeOrderId: null,
      },
    });

    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Router>
            <div className="App">
              <Switch>
                <Route exact path="/shop/all" component={Shop} />
                <Route exact path="/shop/:categoryId" component={CategoryWise} />

                <Route
                  exact
                  path="/product/:id/:variantId"
                  component={ProductDetails}
                />

                <Route exact path="/cart" render={() => <Checkout />} />

                <Route exact path="/about" component={AboutUs} />

                <Route exact path="/loader" component={FullPageLoader} />

                <Route
                  exact
                  path="/addressdetails"
                  component={AddressDetails}
                />

                <Route exact path="/" component={Home} />

                <Route exact path="/contact" component={ContactUs} />
                <Route
                  exact
                  path="/confirmation"
                  component={ConfiramtionPage}
                />
              </Switch>
            </div>
          </Router>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default App;
