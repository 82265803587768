import React from "react";
import { ReUsableSlider6 } from "@inertiasoft/re-usable-sliders";
import techImage from "../../assets/images/post-image.jpg";
import postImage from "../../assets/images/post-image.jpg";
import { testimonialData } from "../../Constants/Constant";
import journal from "../../assets/images/EcommercePics/Home/Our-Journal-(960x360).jpg";
import "./slider.css";

const Slider = () => {
  return (
    <>
      <div className="slider-container">
        <div className="slider-wrapper">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12 testimonial-container"></div>

            <div className="col-lg-4 col-md-5 col-sm-12 main-posts-container">
              <h3>Our Journal</h3>
              <p>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit…
              </p>
              <div className="journal-container">
                <div className="post">
                  <img width="80px" src={techImage} alt="render error" />
                  <div className="post-content">
                    <h4>A Quick Guide to Wearables</h4>
                    <span>
                      <img
                        className="post-img"
                        width="40"
                        src={postImage}
                        alt="render error"
                      />
                      admin <span>August 14, 2018</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="journal-container">
                <div className="post">
                  <img width="80px" src={techImage} alt="render error" />
                  <div className="post-content">
                    <h4>A Quick Guide to Wearables</h4>
                    <span>
                      <img
                        className="post-img"
                        width="40"
                        src={postImage}
                        alt="render error"
                      />
                      admin <span>August 14, 2018</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="journal-container">
                <div className="post">
                  <img width="80px" src={techImage} alt="render error" />
                  <div className="post-content">
                    <h4>A Quick Guide to Wearables</h4>
                    <span>
                      <img
                        className="post-img"
                        width="40"
                        src={postImage}
                        alt="render error"
                      />
                      admin <span>August 14, 2018</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
