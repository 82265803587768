import React from "react";
import "./fullPageLoader.css";

export default function FullPageLoader() {
  return (
    <div>
      <div id="loading"></div>
    </div>
  );
}
