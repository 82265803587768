/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/style-prop-object */
import React from "react";
import NumberFormat from "react-number-format";
import { Query } from "react-apollo";
import { ReUsableCard9 } from "@inertiasoft/re-usable-cards";
import { _paginatedProducts } from "./productcard.vendure";
import "./productcard.css";

const ProductCard = () => {
  return (
    <>
      <div className="product-card-container">
        <div className="row product-wrapper">
          <div className="col-12 weekly-heading">
            <h2>Products</h2>
            {/* <h2>Weekly Savings</h2>
            <h2>Top Rated Product</h2>
            <h2>Random Product</h2> */}
          </div>

          <Query
            query={_paginatedProducts}
            variables={{ options: { skip: 0, take: 12 } }}
          >
            {({ loading, data }) => {
              if (loading) return "Loading...";
              if (data !== undefined) {
                const { products } = data;
                if (products.items.length > 0) {
                  return products.items.map((item) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <br />
                      <ReUsableCard9
                        data={{
                          productLink: `/product/${item.id}/${
                            item.variants.length > 0 ? item.variants[0].id : ""
                          }`,
                          width: "324px",
                          height: "104px",
                          backgroundColor: "#fff",
                          fontFamily: "Open Sans, sans-serif",
                          headingColor: "black",
                          headingSize: "23px",
                          textColor: "black",
                          textSize: "12",
                          productName:
                            item.variants.length > 0
                              ? item.variants[0].name
                              : "",
                          productPrize: (
                            <NumberFormat
                              value={
                                item.variants.length > 0
                                  ? item.variants[0].price / 100
                                  : ""
                              }
                              displayType="text"
                              thousandSeparator
                              prefix={
                                item.variants.length > 0
                                  ? item.variants[0].currencyCode === "USD"
                                    ? "$"
                                    : "PKR"
                                  : "PKR"
                              }
                            />
                          ),
                          productImg:
                            item.variants.featuredAsset === null ||
                            item.variants.featuredAsset === undefined
                              ? `${item.featuredAsset.preview}?preset=small`
                              : `${item.variants.featuredAsset.preview}?preset=small`,
                          productImgWidth: "130px",
                          productImgHeight: "80px",
                        }}
                      />
                    </div>
                  ));
                }
                return (
                  <div>
                    <h3>No Product Exist</h3>
                  </div>
                );
              }
            }}
          </Query>
        </div>
        <br />
        <a className="view-all" href="/shop/all">
          View All Products
        </a>
      </div>
    </>
  );
};

export default ProductCard;
