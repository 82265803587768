import { gql } from 'apollo-boost';

const _singleProduct = gql` query GetProduct ($id: ID!) {  
    product (id: $id) {
      name
      slug
      description
      variants {
        id
        name
        price
        priceWithTax
        currencyCode
        assets {
            id
            name
            source
        }
        featuredAsset {
          preview
        }
      }
      featuredAsset {
        id
        preview
      }
    } }`;

const _paginatedProducts = gql
    `query GetProducts ($options: ProductListOptions) {   
  products (options: $options) {
    items {
      id
      name
      variants {
        id
        name
        price
        priceWithTax
        currencyCode
      }
      featuredAsset {
        id
        preview
      }
    }
    totalItems
  }   
}`;

export { _singleProduct, _paginatedProducts };

