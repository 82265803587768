import productImg from "../assets/images/product-img-89.jpg";
import productImage2 from "../assets/images/pc.jpg";
import productImage1 from "../assets/images/pc.jpg";
import slide1 from "../assets/images/EcommercePics/Home/Slider-01.jpg";
import slide2 from "../assets/images/EcommercePics/Home/Slider-02.jpg";
import slide3 from "../assets/images/EcommercePics/Home/Slider-03.jpg";

export const productData = [
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
  {
    productImg,
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/product",
    width: "324px",
    height: "104px",
  },
];

export const testimonialData = {
  img1: "https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg",
  name1: "Shirley Fultz",
  profession1: "Designer",
  content1: "Its freeing to be able to catch up on customized news",
  rating1: 2.6,

  img2: "https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg",
  name2: "Daniel Keystone",
  profession2: "Architect",
  content2:
    "The simple and intuitive design makes it easy for me use. I highly recommend Fetch to my peers.",
  rating2: 3.403,

  img3: "https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg",
  name3: "Theo Sorel",
  profession3: "Designer",
  content3:
    "I enjoy catching up with Fetch on my laptop, or on my phone when I am on the go!",
  rating3: 4,
};

export const footerData = {
  footer: {
    title: "Get in Touch",
    backgroundColor: "#fff",
    fontColor: "#444444",
    fontSize: "16px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  subscribeForm: {
    successMsg: {
      fontColor: "#263b5e",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    errMsg: {
      fontColor: "#263b5e",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    emailField: {
      placeHolder: "Email Address",
    },
    content: {
      details: "Don’t miss any updates of our new templates and extensions.!",
      fontColor: "rgb(142, 142, 142)",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    button: {
      backgroundColor: "#5e2ced",
      fontColor: "#FFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
      content: "Subscribe",
    },
  },
  footerLinks: {
    Shop: [
      {
        name: "All Products",
        href: "/shop/all",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
    Company: [
      {
        name: "About Us",
        href: "/about",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Contact Us",
        href: "/contact",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  socials: {
    title: "Team Solutions",
    icons: [
      {
        name: "facebook",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "twitter",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "linkedin",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "pinterest",
        href: "#",
        backgroundColor: "#5e2ced",
      },
    ],
  },
};

export const carouselData = [
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    button: "Read More",
    image: slide3,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Tortor Dapibus Commodo Aenean Quam",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.",
    button: "Discover",
    image: slide2,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Phasellus volutpat metus",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
    button: "Buy now",
    image: slide1,
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
];

export const shopData = [
  {
    selectOption: "View Details",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: productImage1,
        width: "260",
        height: "260",
      },
      {
        image2: productImage2,
        width: "260",
        height: "260",
      },
    ],
  },
  {
    selectOption: "View Details",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: productImage1,
        width: "260",
        height: "260",
      },
      {
        image2: productImage2,
        width: "260",
        height: "260",
      },
    ],
  },
  {
    selectOption: "View Details",
    productName: "iPad Pro",
    productPrice: "$499.00",
    productLink: "/",
    productImages: [
      {
        image1: productImage1,
        width: "260",
        height: "260",
      },
      {
        image2: productImage2,
        width: "260",
        height: "260",
      },
    ],
  },
];
